'use client';

import { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { ChevronLeft, Home, Search } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import fetchUser from '../../utils/fetchUser';
import { indianStates, fetchSocieties } from '@/constant/constant';
import { useAuth } from '@/contexts/AuthContext';
import Loader from '@/components/common/Loader';
import { useUser } from '../../contexts/UserContext';
import axiosInstance from '../../utils/axiosInstance';

export default function UserAddress() {
  const [address, setAddress] = useState({
    id: '',
    unitNo: '',
    societyName: '',
    plotNo: '',
    sector: '',
    locality: '',
    type: 'home',
    city: '',
    district: '',
    state: '',
    country: 'India',
    contactNo: '',
    pinCode: '',
  });
  const [residenceType, setResidenceType] = useState('society');
  const [societyQuery, setSocietyQuery] = useState('');
  const [societies, setSocieties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [completeAddress, setCompleteAddress] = useState('');
  const { user, refreshUser } = useUser();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const societySearchRef = useRef(null);
  const NotFoundRef = useRef(null);
  const { logout } = useAuth();
  const state = location.state;

  // Society Search Effect
  useEffect(() => {
    if (residenceType === 'society' && societyQuery.length >= 3) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const results = await fetchSocieties(societyQuery);
          setSocieties(results.length > 0 ? results : []);
        } catch (error) {
          console.error('Error fetching societies:', error);
          toast.error('Failed to fetch societies');
          setSocieties([]);
        } finally {
          setIsLoading(false);
        }
      };

      const debounce = setTimeout(() => {
        fetchData();
      }, 300);

      return () => clearTimeout(debounce);
    } else {
      setSocieties([]);
    }
  }, [societyQuery, residenceType]);

  // Complete Address Update
  const updateCompleteAddress = useCallback(
    (addressData) => {
      if (residenceType === 'society') {
        const addressParts = [
          addressData?.unitNo,
          addressData?.societyName,
          addressData?.plotNo && `Plot/Pocket No ${addressData?.plotNo}`,
          addressData?.sector && `Sector ${addressData?.sector}`,
          addressData?.locality,
          addressData?.city,
          addressData?.state,
          addressData?.district,
          addressData?.pinCode,
          addressData?.country,
        ].filter(Boolean);
        setCompleteAddress(addressParts.join(', '));
      }
    },
    [residenceType]
  );

  // Event Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => {
      const newAddress = { ...prev, [name]: value };
      updateCompleteAddress(newAddress);
      return newAddress;
    });
  };

  const handleSocietySelect = (society) => {
    const newAddress = {
      ...address,
      ...society,
      unitNo: address.unitNo || '',
      contactNo: address.contactNo || '',
      id: address.id || '',
      type: address.type || 'home',
    };
    setAddress(newAddress);
    setSocietyQuery(society.societyName);
    setSocieties([]);
    setIsSelected(true);
    updateCompleteAddress(newAddress);
  };

  const resetAddress = (type) => {
    setAddress({
      id: address.id || '',
      unitNo: address.unitNo || '',
      societyName: '',
      plotNo: '',
      sector: '',
      locality: '',
      type: 'home',
      city: '',
      district: '',
      state: '',
      country: 'India',
      contactNo: address.contactNo || '',
      pinCode: '',
    });
    setSocietyQuery('');
    setResidenceType(type);
    setCompleteAddress('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      residenceType === 'society' &&
      (!address.societyName || address.societyName === 'Not Found')
    ) {
      toast.error('Please select a valid society');
      return;
    }

    const userId = user?.id;
    const headerName = user?.userType === 'user' ? 'X-User-Id' : 'X-Vendor-Id';
    const sessionToken = localStorage.getItem('sessionToken');
    const refreshToken = localStorage.getItem('refreshToken');

    // Check for nearby vendors if user type is "user"
    if (user.userType === 'user') {
      try {
        setIsLoading(true);
        const apiRes = await axiosInstance.get(
          `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/near-by-vendors`,
          {
            params: {
              plotNo: address?.plotNo,
              sector: address?.sector,
              state: address?.state,
            },
            headers: { sessionToken, refreshToken },
          }
        );

        if (!apiRes?.data || apiRes?.data?.length === 0) {
          toast.error('There was no seller in this address! Try choosing other nearby address.');
          return;
        }

        // Update vendors
        const apiData = [
          {
            vendorId: apiRes?.data[0]?.id,
            societyName: apiRes?.data[0]?.name,
            vendorName: apiRes?.data[0]?.name,
            favourite: true,
            contactNo: apiRes?.data[0]?.contactNo,
          },
        ];

        await axiosInstance.put(
          `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/vendors?userId=${user?.id}`,
          apiData,
          {
            headers: { sessionToken, refreshToken },
          }
        );
      } catch (error) {
        console.error(error);
        toast.error(
          'There is porblem in fetching vendor for this address! Please try again after some time'
        );
        return;
      } finally {
        setIsLoading(false);
      }
    }

    // Update address
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.post(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/address`,
        address,
        {
          headers: {
            [headerName]: userId,
            sessionToken,
            refreshToken,
          },
        }
      );

      toast.success('Address updated successfully');
      refreshUser(user?.contactNo);
      navigate(-1, { state: { refresh: true } });
    } catch (error) {
      console.error(error);
      toast.error('Failed to update address');
    } finally {
      setIsLoading(false);
    }
  };

  // Initial Setup Effects
  useEffect(() => {
    if (state?.address) {
      setAddress(state.address);
      setSocietyQuery(state.address.societyName);
      setIsSelected(true);
      updateCompleteAddress(state.address);
    }
  }, [state, updateCompleteAddress]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (societySearchRef.current && !societySearchRef.current.contains(event.target)) {
        setSocieties([]);
        setIsSelected(true);
      }
      if (NotFoundRef.current && !NotFoundRef.current.contains(event.target)) {
        setSocietyQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="min-h-screen">
      {isLoading && (
        <div className="fixed top-0 max-w-md h-full left-1/2 -translate-x-1/2  w-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      <div className="absolute right-2 top-1/2 -translate-y-1/2"></div>
      <div className="max-w-md mx-auto bg-white border-l border-r overflow-hidden md:max-w-2xl">
        <div className="flex items-center justify-start flex-col min-h-screen">
          {/* Header */}
          <header className="fixed max-w-md border-l border-r top-0 w-full z-10 bg-white border-b">
            <div className="container mx-auto px-4 py-4 flex items-center">
              <ChevronLeft onClick={() => navigate(-1)} className="h-5 cursor-pointer w-5" />
              <h1 className="ml-2 text-xl font-semibold">Address</h1>
              <Home
                onClick={() => navigate('/', { replace: true })}
                className="h-6 w-6 cursor-pointer ml-auto"
              />
            </div>
          </header>

          {/* Form */}
          <form className="space-y-4 pt-20 py-4 px-6 w-full">
            <h2 className="text-3xl mb-4 font-bold text-[#3d6b2f]">Update Address</h2>

            {/* Residence Type Selection */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Residence Type</label>
              <div className="flex space-x-4">
                <label className="inline-flex cursor-pointer items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="residenceType"
                    value="society"
                    checked={residenceType === 'society'}
                    onChange={(e) => resetAddress(e.target.value)}
                  />
                  <span className="ml-2">Society</span>
                </label>
                <label className="inline-flex cursor-pointer items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="residenceType"
                    value="complex"
                    checked={residenceType === 'complex'}
                    onChange={(e) => resetAddress(e.target.value)}
                  />
                  <span className="ml-2">House / Landmark</span>
                </label>
              </div>
            </div>

            {/* Society Type Address Form */}
            {residenceType === 'society' ? (
              <div className="space-y-4" ref={societySearchRef}>
                <div className="flex items-center justify-center gap-4">
                  {/* House/Flat No */}
                  <div className="flex flex-col w-1/2">
                    <label className="block text-sm font-medium text-gray-700 relative">
                      House/Flat No
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      value={address.unitNo}
                      onChange={(e) =>
                        setAddress((prev) => ({
                          ...prev,
                          unitNo: e.target.value,
                        }))
                      }
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>

                  {/* Society Search */}
                  <div className="w-1/2 relative">
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Search Societies
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        value={societyQuery}
                        onChange={(e) => {
                          setSocietyQuery(e.target.value);
                          setIsSelected(false);
                        }}
                        className="mt-1 block w-full pl-3 pr-10 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                        placeholder="Type to search..."
                      />
                      <div className="absolute right-2 top-1/2 -translate-y-1/2">
                        {isLoading ? (
                          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900" />
                        ) : (
                          <Search className="h-5 w-5 text-gray-400" />
                        )}
                      </div>
                    </div>

                    {/* Society Search Results */}
                    {societies.length > 0 && !isSelected && (
                      <ul className="absolute w-full z-10 bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-60 overflow-auto">
                        {societies.map((society, index) => (
                          <li
                            key={index}
                            className="px-3 py-2 border-b-[1.7px] border-slate-400 border-dashed last:border-none hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSocietySelect(society)}
                          >
                            <p className="text-black">{society.societyName}</p>
                            <p className="text-gray-500 text-sm">
                              Plot/Pocket No {society.plotNo}, Sector {society.sector}
                            </p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                {/* Complete Address Preview */}
                {address.societyName && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Complete Address
                    </label>
                    <textarea
                      value={completeAddress}
                      readOnly
                      className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
                      rows={3}
                    />
                  </div>
                )}
              </div>
            ) : (
              /* Complex/Landmark Type Address Form */
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      House/Flat No
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="unitNo"
                      value={address.unitNo}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Complex / Landmark
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="societyName"
                      value={address.societyName}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Plot/Pocket No
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="plotNo"
                      value={address.plotNo}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Sector
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="sector"
                      value={address.sector}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Common Address Fields */}
            <div className="space-y-4">
              {residenceType !== 'society' && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Locality
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="locality"
                      value={address.locality}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      City
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={address.city}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                </div>
              )}

              {residenceType !== 'society' && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      State
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <select
                      name="state"
                      value={address.state}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    >
                      <option value="">Select State</option>
                      {indianStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      District (Optional)
                    </label>
                    <input
                      type="text"
                      name="district"
                      value={address.district}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                    />
                  </div>
                </div>
              )}

              {residenceType !== 'society' && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Pin Code
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="pinCode"
                      value={address.pinCode}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                      pattern="[0-9]{6}"
                      inputMode="numeric"
                      maxLength={6}
                      minLength={6}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Country
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="country"
                      value={address.country}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                </div>
              )}

              {/* Contact Details for User Type */}
              {user?.userType === 'user' && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Contact No
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="contactNo"
                      value={address.contactNo}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                      pattern="[0-9]{10}"
                      inputMode="numeric"
                      maxLength={10}
                      minLength={10}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 relative">
                      Address Type
                      <span className="absolute -top-1 -right-2 text-red-400">*</span>
                    </label>
                    <select
                      name="type"
                      value={address.type}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    >
                      <option value="home">Home</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </form>

          {/* Submit Button */}
          <div className="w-full mt-auto mb-2 pb-24 py-2 px-6">
            <button
              onClick={handleSubmit}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#3d6b2f] hover:bg-[#3d6b2f]/90 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={
                (residenceType === 'society' && !address.societyName) ||
                !address.unitNo ||
                !address.locality ||
                !address.city ||
                !address.state ||
                !address.pinCode
              }
            >
              Update
            </button>
          </div>
        </div>
        <BottomNav />
      </div>
    </div>
  );
}

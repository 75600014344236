import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { generateToken } from '@/constant/constant';
import { useNavigate } from 'react-router-dom';
import { onMessage } from 'firebase/messaging';
import { generateFcmToken } from '../utils/firebaseconfig';
// import { messaging, generateFcmToken } from '@/utils/firebaseconfig';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [autoOtp, setAutoOtp] = useState([]);

  function isIOS() {
    const userAgent = navigator.userAgent.toLowerCase();

    return /iphone|ipad|ipod/.test(userAgent);
  }

  const handleSuccessfulAuth = useCallback(async (phone) => {
    try {
      setLoading(true);
      const sessionToken = localStorage.getItem('sessionToken');
      const refreshToken = localStorage.getItem('refreshToken');
      let data;

      let fcmToken;
      if (isIOS()) {
        fcmToken = '';
      } else {
        try {
          fcmToken = await generateFcmToken();
        } catch (error) {
          console.error(error);
          fcmToken = '';
        }
      }

      // console.log(fcmToken);

      const response = await axios.get(
        `${
          import.meta.env.VITE_API_URL
        }/rest/subziwale/api/v1/user?mobileNo=%2B${'91' + phone}&token=${fcmToken}`,
        {
          headers: {
            sessionToken,
            refreshToken,
          },
        }
      );

      data = await response.data;

      if (data.vendor === null && data.user === null) {
        navigate(`/auth/address/${phone}`);
        return null;
      } else if (data.vendor) {
        const vendor = data.vendor;
        if (vendor.status !== 'Reject' && vendor.status !== 'Pending') {
          const token = generateToken(vendor);
          setIsAuthenticated(true);
          setUserRole('vendor');
          setUser(vendor);
          localStorage.setItem('contactNo', vendor?.contactNo);
          localStorage.setItem('token', token);
          localStorage.setItem('userRole', 'vendor');
          navigate('/vendor', { replace: true });
          return vendor;
        } else {
          toast.error('Your registration is under process! Contact administrator @9899784200');
          navigate('/login', { replace: true });
          return null;
        }
      } else {
        const user = data?.user;

        localStorage.setItem('contactNo', user?.contactNo);
        if (user?.addressModel && user.addressModel.length > 0) {
          const token = generateToken(user);
          setIsAuthenticated(true);
          setUserRole('user');
          setUser(user);
          localStorage.setItem('token', token);
          localStorage.setItem('userRole', 'user');
          localStorage.setItem('contactNo', user?.contactNo);
          navigate('/', { replace: true });
          return user;
        } else {
          navigate(`/auth/address/${phone}`);
          return user;
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(error || 'Internal sever error');
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const initiateLogin = async (phone) => {
    setLoading(true);
    try {
      if (window.OTPlessSignin) {
        const apires = window.OTPlessSignin.initiate({
          channel: 'PHONE',
          phone: phone,
          countryCode: '+91',
          deliveryChannel: 'SMS',
          otpLength: 4,
        });

        const res = await apires;

        if (res.success) {
          setPhoneNumber(phone);
          return true;
        } else {
          console.error(res);
          toast.error(res.response.errorMessage || 'Failed to send OTP');
          return false;
        }
      } else {
        throw new Error('Failed to send Otp. Please try again.');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Failed to send Otp. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = useCallback(async (otp, phone) => {
    setLoading(true);
    try {
      if (window.OTPlessSignin) {
        const res = await window.OTPlessSignin.verify({
          channel: 'PHONE',
          phone: phone,
          otp: otp,
          countryCode: '+91',
        });

        if (res.success) {
          setPhoneNumber(phone);
          return true;
        } else {
          console.error(res.response.errorMessage);
          toast.error(
            res.response.errorMessage == 'Request error: Incorrect OTP!'
              ? 'Incorrect OTP!'
              : res.response.errorMessage
          );
          return false;
        }
      } else {
        throw new Error('OTPless SDK not loaded');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Failed to verify OTP');
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = async () => {
    try {
      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        setUser(null);
        localStorage.clear();
        navigate('/login');
        return;
      }

      await axios.post(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/logout`,
        {},
        {
          headers: {
            sessionToken: sessionToken,
          },
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setUser(null);
      localStorage.clear();
      navigate('/login');
    }
  };

  const value = {
    isAuthenticated,
    userRole,
    initiateLogin,
    verifyOTP,
    logout,
    autoOtp,
    setUserRole,
    loading,
    phoneNumber,
    setPhoneNumber,
    user,
    handleSuccessfulAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

import { Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from '@/contexts/AuthContext';
import { CartProvider } from '@/hooks/useCart';
import { VendorProvider } from '@/contexts/VendorContext';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import AuthAddress from '@/pages/Auth/AuthAddress';
import OrderPage from '@/pages/vendor/OrderPage';
import Dashboard from '@/pages/DashboardPage';
import OrderHistory from '@/components/orders/OrderHistory';
import OrderDetails from '@/components/orders/OrderDetails';
import ShoppingCart from '@/components/common/ShoppingCart';
import HistoryDetails from '@/pages/user/HistoryDetails';
import UserAddress from '@/pages/user/UserAddress';
import Profile from '@/pages/user/Profile';
import VendorProfile from '@/pages/vendor/VendorProfile';
import Settings from '@/pages/vendor/Settings';
import VendorServiceAreas from '@/pages/vendor/VendorServiceAreas';
import SearchPage from '@/components/products/SearchPage';
import ProductPricing from '@/pages/vendor/ProductPricing';
import VendorRoute from '@/utils/ProtectedRoutes/VendorRoute';
import UserRoute from '@/utils/ProtectedRoutes/UserRoute';
import LoginPage from '@/pages/Auth/LoginPage';
import OTPPage from '@/pages/Auth/OTPPage';
import AuthDetails from './pages/Auth/AuthDetails';
import { UserProvider } from './contexts/UserContext';

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <UserProvider>
          <CartProvider>
            <VendorProvider>
              <div className="max-w-md mx-auto">
                <Routes>
                  {/* Public routes */}
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/auth/otp" element={<OTPPage />} />
                  <Route path="/auth/address/:phone" element={<AuthAddress />} />
                  <Route path="/auth/personal" element={<AuthDetails />} />

                  {/* Protected vendor routes */}
                  <Route path="/vendor/" element={<VendorRoute />}>
                    <Route index element={<OrderPage />} />
                    <Route path="store" element={<Dashboard />} />
                    <Route path="profile/settings" element={<Settings />} />
                    <Route path="profile" element={<VendorProfile />} />
                    <Route path="address" element={<UserAddress />} />
                    <Route path="service/edit" element={<VendorServiceAreas />} />
                    <Route path="store/search" element={<SearchPage />} />
                    <Route path="product/pricing" element={<ProductPricing />} />
                    <Route path="orders/:id" element={<OrderDetails />} />
                  </Route>

                  {/* Protected user routes */}
                  <Route path="/" element={<UserRoute />}>
                    <Route index element={<Dashboard />} />
                    <Route path="search" element={<SearchPage />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="address" element={<UserAddress />} />
                    <Route path="cart" element={<ShoppingCart />} />
                    <Route path="history" element={<OrderHistory />} />
                    <Route path="history/:id" element={<HistoryDetails />} />
                  </Route>

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={true}
                  closeOnClick={true}
                  rtl={false}
                  draggable
                  theme="light"
                  transition={Bounce}
                />
              </div>
            </VendorProvider>
          </CartProvider>
        </UserProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;

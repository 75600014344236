export function sortOrders(orders, status) {
  if (status === 'Completed') {
    return orders.sort((a, b) => {
      // Express orders come first

      if (a.status === 'Paid' && b.status === 'Completed') return 1;
      if (b.status === 'Paid' && a.status === 'Completed') return -1;

      // If both are express or both are not express, sort by delivery date and time
      const dateA = new Date(`${a.deliveryDate} ${a.timeSlot.split('-')[0]}`);
      const dateB = new Date(`${b.deliveryDate} ${b.timeSlot.split('-')[0]}`);

      return dateB.getTime() - dateA.getTime();
    });
  }

  return orders.sort((a, b) => {
    // Express orders come first
    if (a.expressDelivery && !b.expressDelivery) return -1;
    if (!a.expressDelivery && b.expressDelivery) return 1;

    // If both are express or both are not express, sort by delivery date and time
    const dateA = new Date(`${a.deliveryDate} ${a.timeSlot.split('-')[0]}`);
    const dateB = new Date(`${b.deliveryDate} ${b.timeSlot.split('-')[0]}`);

    return dateA.getTime() - dateB.getTime();
  });
}

import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';

const UserRoute = () => {
  const { logout } = useAuth();
  const { user } = useUser();
  const token = localStorage.getItem('token');
  const phone = localStorage.getItem('contactNo');
  if (token && phone) {
    if (user?.userType === 'vendor') {
      return <Navigate to="/vendor" replace />;
    }
    return <Outlet />;
  }

  logout();
  return <Navigate to="/login" replace />;
};

export default UserRoute;

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyA2MFOeslzeEA6G5pElJEHM9aRMSpOld9Q',
  authDomain: 'subziwale-11c22.firebaseapp.com',
  projectId: 'subziwale-11c22',
  storageBucket: 'subziwale-11c22.firebasestorage.app',
  messagingSenderId: '19072696960',
  appId: '1:19072696960:web:8bac6345f31eca3eb1dc65',
  measurementId: 'G-VDS5BCXYF5',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const messaging = getMessaging(app);

export const generateFcmToken = async () => {
  const permission = await Notification.requestPermission();
  // console.log(permission);

  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey:
        'BFpu2_b54aL4zAUDSOTY9Fd5Tf4gimTmWptEisM0pUsZJOfXv_NHf99R8qWcIXuXtoCFDLlY5TQPUqno4NlabWE',
    });

    // console.log(token);
    return token;
  } else if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:
          'BCjyBJMCyivLkykk_lQ9Cwm6DdeKI7H1wjWdDwdUS7T_2iy2uXMnhJmLhPRm-HngbEFxkutmzGXVqvwXdrnWIzg',
      });

      // console.log(token);
      return token;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

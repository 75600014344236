'use client';

import { useRef, useEffect, useState, useMemo } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Link } from 'react-router-dom';
import { useVendor } from '@/contexts/VendorContext';
import { useCart } from '@/hooks/useCart';
import axiosInstance from '@/utils/axiosInstance';
import { Search, ChevronLeft, ChevronRight, Check, User } from 'lucide-react';
import { useUser } from '@/contexts/UserContext';

export default function HeroHeader({ setCategories }) {
  const { selectedVendor, vendors, setSelectedVendor } = useVendor();
  const { user } = useUser();
  const userRole = user?.userType;
  const selectedLocalVendor =
    selectedVendor || JSON.parse(localStorage.getItem('selectedVendor') || '{}');

  return (
    <div className="w-full">
      {/* Header */}
      <header className="bg-white fixed w-full max-w-md top-0 z-30 shadow-sm">
        <div className="max-w-md mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="text-xl text-green-700 font-bold">SubziWale</div>
            </div>
            <div className="flex items-center space-x-4">
              <Link to="search" aria-label="Search" className="p-1">
                <Search className="h-5 w-5" />
              </Link>
              <Link to={userRole === 'vendor' ? '/vendor/profile' : '/profile'}>
                <div className="h-9 w-9 rounded-full bg-green-700 text-white flex items-center justify-center  font-medium">
                  {/* {user?.name?.slice(0, 1) || 'JD'} */}
                  {user?.name?.slice(0, 1) === '+' ? (
                    <User className="w-8" />
                  ) : (
                    user?.name?.slice(0, 1) || <User className="w-8" />
                  )}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/* Vendor Stories */}
      {userRole === 'user' && vendors?.length > 0 && (
        <VendorStories
          vendors={vendors}
          selectedVendor={selectedLocalVendor}
          setSelectedVendor={setSelectedVendor}
          setCategories={setCategories}
          user={user}
        />
      )}
    </div>
  );
}

// Vendor Stories Component
const VendorStories = ({ vendors, selectedVendor, setSelectedVendor, setCategories, user }) => {
  const scrollRef = useRef(null);
  const { clearCart } = useCart();
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [activeVendor, setActiveVendor] = useState(null);

  // Filter out duplicate vendors based on vendor ID
  const uniqueVendors = useMemo(() => {
    const vendorMap = new Map();

    // If vendors array exists, filter out duplicates
    if (vendors && vendors.length > 0) {
      vendors.forEach((vendor) => {
        // Only keep the first occurrence of each vendor ID
        if (!vendorMap.has(vendor.id)) {
          vendorMap.set(vendor.id, vendor);
        }
      });

      // Convert map values back to array
      return Array.from(vendorMap.values());
    }

    return [];
  }, [vendors]);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10);
      }
    };

    checkScroll();
    const scrollElement = scrollRef.current;
    scrollElement?.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);

    return () => {
      scrollElement?.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  // Scroll to selected vendor on initial load
  useEffect(() => {
    if (selectedVendor?.vendorId && vendors?.length && scrollRef.current) {
      scrollRef.current.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [selectedVendor?.vendorId, vendors]);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { clientWidth } = scrollRef.current;
      const scrollAmount = direction === 'left' ? -clientWidth / 1.5 : clientWidth / 1.5;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const selectVendor = async (vendor) => {
    // Show loading state
    setActiveVendor(vendor.id);

    const selectedData = {
      vendorName: vendor?.name,
      shopName: vendor?.shopName,
      vendorId: vendor?.id,
      societyName: vendor?.address?.societyName,
      contactNo: vendor?.contactNo,
      status: vendor?.status,
      deliveryCharge: vendor?.deliveryCharge,
      deliveryChargeWaived: vendor?.deliveryChargeWaived,
      minimumOrder: vendor?.minimumOrder,
      vendorCategories: vendor?.vendorCategories,
    };

    const apiData = [
      {
        vendorId: selectedData?.vendorId,
        societyName: selectedData?.societyName,
        vendorName: selectedData?.vendorName,
        favourite: true,
        contactNo: selectedData?.contactNo,
      },
    ];

    const sessionToken = localStorage.getItem('sessionToken');
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/vendors?userId=${user?.id}`,
        apiData,
        {
          headers: {
            sessionToken,
            refreshToken,
          },
        }
      );

      setSelectedVendor(selectedData);
      clearCart();
      setCategories(selectedData?.vendorCategories);
    } catch (error) {
      console.log(error);
    } finally {
      setActiveVendor(null);
    }
  };

  return (
    <div className="bg-white mt-14 py-2">
      <div className="relative">
        {/* Left Scroll Button */}
        {canScrollLeft && (
          <button
            onClick={() => scroll('left')}
            className="absolute -left-1 top-1/2 -translate-y-1/2 z-10 bg-white/90 rounded-full p-1.5 shadow-md hover:bg-white transition-all duration-200"
            aria-label="Scroll left"
          >
            <ChevronLeft className="h-5 w-5 text-gray-700" />
          </button>
        )}

        {/* Scrollable Vendor List */}
        <div
          ref={scrollRef}
          className="flex overflow-x-auto pt-2 px-4 gap-1 scrollbar-hide"
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        >
          {uniqueVendors.map((vendor, index) => {
            const isSelected = selectedVendor?.vendorId === vendor.id;
            const isLoading = activeVendor === vendor.id;
            const shopName = vendor.shopName || `${vendor.name}'s Store`;
            const initial = shopName.charAt(0).toUpperCase();

            return (
              <div
                key={vendor.id || index}
                onClick={() => !isSelected && !isLoading && selectVendor(vendor)}
                className={`flex flex-col group relative cursor-pointer items-center min-w-[70px] ${isSelected ? 'order-1' : 'order-2'}`}
              >
                <div
                  className={`relative group cursor-pointer ${isSelected ? 'ring-2  ring-green-600 rounded-full' : ''}`}
                >
                  <Avatar className="w-14 h-14 border">
                    <AvatarImage
                      src={vendor.profileImage || `/placeholder.svg?height=64&width=64`}
                      alt={shopName}
                    />
                    <AvatarFallback className="bg-indigo-100 text-indigo-800">
                      {initial}
                    </AvatarFallback>
                  </Avatar>
                  {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black/20 rounded-full">
                      <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    </div>
                  )}

                  {/* Selected Check Mark */}
                  {isSelected && (
                    <>
                      <div className="absolute -bottom-1 -right-1 bg-green-600 rounded-full p-1 border-2 border-white shadow-sm">
                        <Check className="h-3 w-3 text-white" />
                      </div>
                      <div className="absolute -bottom-3 -translate-x-1/2 left-1/2 w-full bg-green-600 rounded-sm text-xs text-white h-1 px-1 text-center">
                        {/* <Check className="h-3 w-3 text-white" /> */}
                        {/* Selected Seller */}
                      </div>
                    </>
                  )}
                </div>
                <p className="text-xs mt-3 text-center w-28 text-balance line-clamp-3 h-12 overflow-hidden">
                  {shopName} Fresh Fruits
                </p>
                <div className="absolute w-36 border border-green-600 px-2 py-1.5 text-white text-xs z-50 bg-black/90 rounded-md bottom-0 left-0 group-hover:block hidden shadow-lg">
                  {shopName}
                </div>
              </div>
            );
          })}
        </div>

        {/* Right Scroll Button */}
        {canScrollRight && (
          <button
            onClick={() => scroll('right')}
            className="absolute -right-1 top-1/2 -translate-y-1/2 z-10 bg-white/90 rounded-full p-1.5 shadow-md hover:bg-white transition-all duration-200"
            aria-label="Scroll right"
          >
            <ChevronRight className="h-5 w-5 text-gray-700" />
          </button>
        )}
      </div>
    </div>
  );
};

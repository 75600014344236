import { useEffect, useRef, useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { TermsAndConditionsPopup } from '@/components/modals/TermsAndConditionsPopup';
import { PrivacyPolicyPopup } from '@/components/modals/PrivacyPolicyPopup';
import { toast } from 'react-toastify';
import { BookOpenText, Download, Mail, Leaf } from 'lucide-react';
import { getPWADisplayMode, isIOS, isStandalone } from '@/utils/PWAUtils';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import emailjs from '@emailjs/browser';

export default function LoginPage() {
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const { initiateLogin, loading, handleSuccessfulAuth } = useAuth();
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);
  const [isIosModalOpen, setIsIosModalOpen] = useState(false);

  useEffect(() => {
    const displayMode = getPWADisplayMode();
    if (displayMode === 'browser') {
      if (isIOS()) {
        // iOS specific logic
      } else if ('getInstalledRelatedApps' in navigator) {
        navigator.getInstalledRelatedApps().then((apps) => {
          if (apps.length > 0) {
            window.location.replace(window.location.href);
          }
        });
      }
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setInstallPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (phone.replace(/\s/g, '').length !== 10) {
      toast.error('Please enter a valid 10-digit phone number');
      return;
    }

    const success = await initiateLogin(phone);
    if (success) {
      navigate('/auth/otp', { replace: true });
    } else {
      console.log(success);
    }
  };

  const installPwa = async () => {
    if (isIOS()) {
      setIsIosModalOpen(true);
      return;
    }
    if (!installPrompt) {
      toast.info('Already Intsalled or reload the browser if not');
      return;
    }

    try {
      const choiceResult = await installPrompt.prompt();
      if (choiceResult.outcome === 'accepted') {
        localStorage.setItem('pwaPromptDismissed', 'true');
      } else {
        toast.info('Install anytime from your browser settings for a better experience');
      }
    } catch (error) {
      console.error('Error installing PWA:', error);
    }

    setInstallPrompt(null);
  };

  const initializeOtpLess = () => {
    if (window.OTPless) {
      const OTPlessSignin = new window.OTPless((eventCallback) => {
        const ONETAP = () => {
          const { response } = eventCallback;
          const sessionToken = response.sessionInfo.sessionToken;
          const refreshToken = response.sessionInfo.refreshToken;

          if (response.status) {
            localStorage.setItem('sessionToken', sessionToken);
            localStorage.setItem('refreshToken', refreshToken);

            const data = handleSuccessfulAuth(response.identities[0].identityValue.slice(2));
          }
        };

        const OTP_AUTO_READ = () => {
          const {
            response: { otp },
          } = eventCallback;

          setAutoOtp(otp.split(''));
        };

        const FAILED = () => {
          const { response } = eventCallback;
          console.log('response', { response });
        };

        const FALLBACK_TRIGGERED = () => {
          const { response } = eventCallback;

          console.log({ response });
        };

        const EVENTS_MAP = { ONETAP, OTP_AUTO_READ, FAILED, FALLBACK_TRIGGERED };

        if ('responseType' in eventCallback) EVENTS_MAP[eventCallback.responseType]();
      });

      console.log('otpless initiate');

      window.OTPlessSignin = OTPlessSignin;
    }
  };

  useEffect(() => {
    initializeOtpLess();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -right-24 -top-24 w-48 h-48 rounded-full bg-green-100/50" />
        <div className="absolute -left-24 top-1/3 w-48 h-48 rounded-full bg-orange-100/50" />
        <div className="absolute right-12 bottom-1/3 w-24 h-24 rounded-full bg-yellow-100/50" />
      </div>

      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 z-50 bg-black/20 flex items-center justify-center backdrop-blur-sm">
          <div className="animate-pulse flex flex-col items-center">
            <img src="/images/leaf2.png" className="w-12 h-12 animate-bounce" alt="logo" />
            <p className="text-white font-medium mt-2">Loading...</p>
          </div>
        </div>
      )}

      <div className="min-h-screen flex flex-col px-6 py-8 relative z-10">
        <div className="flex-1 flex flex-col items-center justify-center max-w-md mx-auto w-full">
          {/* Logo */}
          <div className="w-48 mb-8 transform hover:scale-105 transition-transform duration-300">
            <img
              src="/images/logo.png"
              alt="SubziWale Logo"
              className="w-full object-contain"
              loading="eager"
            />
          </div>

          {/* Welcome Text */}
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-green-800">Welcome to SubziWale</h1>
            <p className="text-gray-600 mt-2">Fresh fruits & vegetables at your doorstep</p>
          </div>

          {/* Login Form */}
          <form onSubmit={handleLogin} className="w-full space-y-4">
            <div className="relative">
              <div className="w-full bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border-2 border-green-100 overflow-hidden flex items-center">
                <span className="pl-4 pr-2 py-3 border-r border-green-100">
                  <img src="/images/flag.svg" className="w-6" alt="Indian Flag" />
                </span>
                <span className="px-2 text-gray-700 font-medium">+91</span>
                <Input
                  type="text"
                  className="flex-1 border-0 focus-visible:ring-0 focus-visible:ring-offset-0 bg-transparent text-lg"
                  value={phone}
                  pattern="[0-9]{10}"
                  inputMode="numeric"
                  minLength={10}
                  maxLength={10}
                  placeholder="Enter phone number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <Button
              type="submit"
              disabled={loading}
              className="w-full h-12 bg-green-700 hover:bg-green-800 rounded-xl text-white font-medium text-lg shadow-lg shadow-green-100/50"
            >
              Continue
            </Button>
          </form>

          {/* Action Buttons */}
          <div className="mt-6 flex flex-wrap gap-3 w-full justify-center">
            <Button
              variant="outline"
              className="flex-1 h-12 border-green-700 text-green-700 rounded-xl hover:bg-green-50"
              onClick={() => setIsContactUsOpen(true)}
            >
              <Mail className="w-5 h-5 mr-2" />
              Contact Us
            </Button>
            <Button
              variant="outline"
              className="flex-1 h-12 border-green-700 text-green-700 rounded-xl hover:bg-green-50"
              onClick={() => window.open('https://about.subziwale.com/', '_blank')}
            >
              <BookOpenText className="w-5 h-5 mr-2" />
              About Us
            </Button>
            {!isStandalone() && (
              <Button
                variant="outline"
                className="flex-1 h-12 border-green-700 text-green-700 rounded-xl hover:bg-green-50"
                onClick={installPwa}
              >
                <Download className="w-5 h-5 mr-2" />
                Install App
              </Button>
            )}
          </div>

          {/* Terms and Privacy */}
          <p className="mt-6 text-sm text-center text-gray-600">
            By continuing, you agree to our{' '}
            <button
              type="button"
              onClick={() => setIsTermsOpen(true)}
              className="text-green-700 hover:underline font-medium"
            >
              Terms
            </button>{' '}
            &{' '}
            <button
              type="button"
              onClick={() => setIsPrivacyOpen(true)}
              className="text-green-700 hover:underline font-medium"
            >
              Privacy Policy
            </button>
          </p>
        </div>

        {/* Decorative Bottom Pattern */}
        <div className="absolute bottom-0 left-0 right-0 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="text-green-700/10"
          >
            <path
              fill="currentColor"
              fillOpacity="1"
              d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>

      {/* Modals */}
      <EmailForm isOpen={isContactUsOpen} onClose={() => setIsContactUsOpen(false)} />
      <TermsAndConditionsPopup isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} />
      <PrivacyPolicyPopup isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} />

      {/* iOS Installation Modal */}
      <Dialog open={isIosModalOpen} onOpenChange={setIsIosModalOpen}>
        <DialogContent className="sm:max-w-md w-auto max-h-screen p-0 overflow-hidden">
          <img
            src="/iostut.png"
            alt="iOS installation tutorial"
            className="h-auto w-full rounded-lg"
            loading="lazy"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

// Email Form Component
const EmailForm = ({ isOpen, onClose }) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        import.meta.env?.VITE_EMAILJS_SERVICE_ID,
        import.meta.env?.VITE_EMAILJS_TEMPLATE_ID,
        form.current,
        {
          publicKey: import.meta.env?.VITE_EMAILJS_API_KEY,
        }
      )
      .then(
        () => {
          toast.success('Email Sent ✅');
          setLoading(false);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setLoading(false);
        }
      )
      .catch((error) => {
        console.error(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-sm pt-5 pb-3 rounded-lg">
        <DialogHeader className="py-0">
          <DialogTitle className="flex items-center">
            <Mail className="mr-2 h-5 w-5 text-green-600" />
            Contact Us
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={sendEmail} ref={form} className="space-y-2">
          <div className="space-y-1">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="user_name"
              placeholder="Your name"
              className="focus-visible:ring-green-500 focus-visible:ring-1 focus-visible:ring-offset-0"
              required
            />
          </div>

          <div className="space-y-1">
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="user_email"
              placeholder="Your email address"
              className="focus-visible:ring-green-500 focus-visible:ring-offset-0 focus-visible:ring-1"
              required
            />
          </div>

          <div className="space-y-1">
            <Label htmlFor="message">Message</Label>
            <textarea
              id="message"
              name="message"
              rows={3}
              placeholder="How can we help you?"
              className="focus-visible:ring-green-500 block border focus-visible:ring-offset-0 focus-visible:ring-1 w-full focus:outline-none rounded-sm p-2"
              required
            />
          </div>

          <div className="flex items-center mt-4 gap-3 justify-end">
            <Button type="button" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading} className="bg-green-700 hover:bg-green-800">
              {loading ? 'Sending...' : 'Send Message'}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

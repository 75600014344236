import { useEffect, useState, useCallback } from 'react';
import {
  MapPin,
  Phone,
  X,
  Plus,
  Minus,
  Edit,
  XCircle,
  PlusCircle,
  MinusCircle,
  ArrowLeft,
  Notebook,
  Bike,
  ShoppingBag,
  Save,
} from 'lucide-react';
import Loader from '@/components/common/Loader';
import { Button } from '@/components/ui/button';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { useNavigate, useParams } from 'react-router-dom';
import { format, addDays, isSameDay, parse, isAfter } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toast } from 'react-toastify';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import axiosInstance from '../../utils/axiosInstance';
import { useUser } from '../../contexts/UserContext';

const timeSlots = ['10:00-12:00', '12:00-14:00', '14:00-16:00', '16:00-18:00', '18:00-20:00'];

const formatTimeSlot = (slot) => {
  const [start, end] = slot.split('-');
  return `${formatTime(start)} - ${formatTime(end)}`;
};

const formatTime = (time) => {
  const [hours, minutes] = time.split(':');
  const date = new Date(0, 0, 0, Number.parseInt(hours), Number.parseInt(minutes));
  return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
};

export default function OrderDetails() {
  const [isAllSlotsOccupiedForToday, setIsAllSlotsOccupiedForToday] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editedItems, setEditedItems] = useState([]);
  const [tempOrder, setTempOrder] = useState(null);
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);
  const [delivery, setDelivery] = useState(0);
  const { user } = useUser();
  // New state for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  const fetchOrderDetails = useCallback(async () => {
    const { id } = params;

    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order/details?orderId=${id}`,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      setDelivery(data?.deliveryCharges);
      setTempOrder(data);
      setEditedItems(data.items.map((item) => ({ ...item, edited: false })));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchOrderDetails();
  }, [fetchOrderDetails]);

  useEffect(() => {
    if (tempOrder) {
      setOrder(tempOrder);
      setTempOrder(null);
    }
  }, [tempOrder]);

  const handleCancelOrder = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order/status?orderId=${params?.id}&status=Cancelled`,
        {
          cancellationReason: cancelReason,
        },
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );
      toast.success('Order Cancelled Successfully');
      navigate('/vendor?tab=Cancelled');
    } catch (error) {
      console.error('Error cancelling order:', error);
      toast.error(error.message || 'Failed to cancel order! Please try again');
    } finally {
      setLoading(false);
      setIsCancelModalOpen(false);
    }
  };

  const handleRescheduleOrder = async () => {
    const data = {
      date: format(rescheduleDate, 'yyyy-MM-dd'),
      selectedTimeSlot,
    };

    if (new Date(data.date).getDate() == new Date().getDate()) {
      const currentHour = new Date().getHours();
      const selectedHour = selectedTimeSlot.split('-')[0].split(':')[0];

      if (currentHour >= selectedHour) {
        toast.error('Slot Not Available! Please Choose Next Available Slot');
        return;
      }
    }
    try {
      setLoading(true);
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order/status?orderId=${params?.id}&status=Rescheduled`,
        {
          rescheduleDeliveryDate: format(rescheduleDate, 'yyyy-MM-dd'),
          rescheduleTimeSlot: selectedTimeSlot,
        },
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      toast.success('Order Rescheduled Successfully');
      navigate('/vendor?tab=Rescheduled');
    } catch (error) {
      console.error('Error rescheduling order:', error);
      toast.error('Failed to reschedule order. Please try again.');
    } finally {
      setLoading(false);
      setIsRescheduleModalOpen(false);
    }
  };

  const handleMarkDelivered = async (status) => {
    try {
      setLoading(true);
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order/status?orderId=${params?.id}&status=${status}`,
        {},
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );
      if (status === 'Completed') {
        toast.success('Order Delivered Successfully');
        navigate('/vendor?tab=Completed');
      } else {
        toast.success('Payment Done');
        navigate('/vendor?tab=Paid');
      }
    } catch (error) {
      console.error('Error marking order as delivered:', error);
      toast.error('Failed to update order status! Please Try Again');
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (index, change) => {
    const newItems = editedItems.map((item, i) => {
      if (i === index) {
        const newQuantity = Math.max(0, item.quantity + change);
        const newTotal = newQuantity * item.netPrice;
        return {
          ...item,
          quantity: newQuantity,
          total: newTotal,
          edited: true,
        };
      }
      return item;
    });

    setEditedItems(newItems);
    updateOrderSummary(newItems);
  };

  const updateOrderSummary = (items) => {
    const mrpTotal = items.reduce((sum, item) => sum + item.mrp * item.quantity, 0);
    const netTotal = items.reduce((sum, item) => sum + item.netPrice * item.quantity, 0);
    const discount = mrpTotal - netTotal;
    const total = netTotal + (order?.platformFees || 0) + (order?.deliveryCharges || 0);

    setOrder((prevOrder) => ({
      ...prevOrder,
      mrp: mrpTotal,
      discount: discount,
      total: total,
    }));
  };

  const handleSubmitChanges = async () => {
    try {
      setLoading(true);
      const updatedOrderData = {
        ...order,
        total: order.total,
        items: editedItems,
        note: order?.note || 'hii',
      };
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order?orderId=${params?.id}`,
        updatedOrderData,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );
      fetchOrderDetails();
      toast.success('Order updated successfully');
      setIsEditMode(false);
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Failed to update order! Please try again');
    } finally {
      setLoading(false);
    }
  };

  // Cancel editing without saving changes
  const handleCancelEdit = () => {
    // Reset to original order data
    fetchOrderDetails();
    setIsEditMode(false);
  };

  const isSlotOccupied = (slot) => {
    const [start] = slot.split('-');
    const slotTime = parse(start, 'HH:mm', new Date());
    const currentTime = new Date();

    if (isSameDay(rescheduleDate, new Date())) {
      return isAfter(currentTime, slotTime);
    }
    return false;
  };

  const handleAddDeliveryCharge = async (deliveryAmount) => {
    if (order?.status !== 'Pending' && order?.status !== 'Rescheduled') {
      toast.info('Order is Already Delivered!');
      return;
    }

    let updatedDelivery;
    let mrp;
    let totalAmount;

    if (order?.deliveryCharges < deliveryAmount) {
      updatedDelivery = deliveryAmount;
      mrp = order?.mrp;
      totalAmount = order?.total + (Number(deliveryAmount) - order?.deliveryCharges);
    } else {
      updatedDelivery = deliveryAmount;
      mrp = order?.mrp;
      totalAmount = order?.total - (order?.deliveryCharges - Number(deliveryAmount));
    }

    const updatedOrder = {
      ...order,
      deliveryCharges: Number(updatedDelivery),
      mrp,
      total: totalAmount,
    };

    try {
      setLoading(true);
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order?orderId=${params?.id}`,
        updatedOrder,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      fetchOrderDetails();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setIsDeliveryModalOpen(false);
    }
  };

  useEffect(() => {
    const allOccupied = timeSlots.every(isSlotOccupied);
    setIsAllSlotsOccupiedForToday(allOccupied);
  }, [rescheduleDate]);

  const cancelReasons = [
    'Incorrect address',
    'Customer not responding',
    'Denied delivery',
    'Unavailable',
    'Other',
  ];

  // Check if order can be modified
  const canModifyOrder =
    order &&
    order.status !== 'Cancelled' &&
    order.status !== 'Completed' &&
    order.status !== 'Paid';

  return (
    <div className="bg-gray-50">
      <header className="sticky top-0 z-10 bg-white px-4 py-3 shadow-sm">
        <div className="flex items-center gap-4">
          <button onClick={() => navigate(-1)}>
            <ArrowLeft className="h-6 w-6 font-normal cursor-pointer" />
          </button>
          <h1 className="text-lg font-medium">Order Details</h1>
        </div>
      </header>
      {!tempOrder && loading && (
        <div className="fixed top-0 max-w-md h-full w-full flex items-center justify-center">
          <Loader />
        </div>
      )}

      {(order || tempOrder) && (
        <main className="mx-auto border-l pb-16 border-r max-w-md space-y-4 p-4">
          <section className="rounded-lg bg-white p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-medium">Order Items</h2>
              {/* Modify Order button - only show when not in edit mode and order can be modified */}
              {canModifyOrder && !isEditMode && (
                <Button
                  onClick={() => setIsEditMode(true)}
                  variant="outline"
                  className="flex items-center gap-1"
                >
                  <Edit className="h-4 w-4" />
                  Modify Order
                </Button>
              )}
            </div>
            <div className="space-y-4">
              {editedItems.map((item, index) => (
                <div key={item.inventoryId + '-' + index} className="flex items-center gap-3">
                  <img
                    loading="lazy"
                    src={item.productImageUrl || '/placeholder.svg'}
                    alt={item.productName}
                    className="rounded-md w-16 h-16 object-cover"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = '/placeholder.svg?height=64&width=64';
                    }}
                  />
                  <div className="flex-1">
                    <h3 className="font-medium text-sm">{item.productName}</h3>
                  </div>

                  {/* Quantity controls - only show in edit mode */}
                  {isEditMode ? (
                    <div className="flex items-center gap-2">
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => handleQuantityChange(index, -1)}
                      >
                        <Minus className="h-4 w-4" />
                      </Button>
                      <span className="text-sm font-medium">{item.quantity}</span>
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => handleQuantityChange(index, 1)}
                      >
                        <Plus className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : null}

                  <div className="text-right">
                    <p className="font-medium text-sm">
                      {item?.quantity} {item?.unit !== 'pc' && 'X'}
                      {item.variant} {item.unit}
                    </p>
                    <p className="text-xs text-gray-500">₹ {item.netPrice * item.quantity}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Edit mode action buttons */}
            {isEditMode && (
              <div className="mt-4 flex gap-2 justify-end">
                <Button
                  variant="outline"
                  onClick={handleCancelEdit}
                  className="flex items-center gap-1"
                >
                  <X className="h-4 w-4" />
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmitChanges}
                  className="bg-green-600 hover:bg-green-700 flex items-center gap-1"
                  disabled={!editedItems.some((item) => item.edited)}
                >
                  <Save className="h-4 w-4" />
                  Save Changes
                </Button>
              </div>
            )}
          </section>

          <section className="rounded-lg bg-white px-4 py-3">
            <div className="flex mb-3 items-center justify-between">
              <h2 className="font-medium">Order Summary</h2>
            </div>
            <div className="space-y-2 text-sm">
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-600 flex items-center gap-1">
                    <Notebook className="h-4 w-4 inline-block" /> Items total
                  </span>
                  <span className="text-xs px-[6px] py-[.5px] bg-blue-50 rounded-full text-blue-600 font-medium">
                    Saved ₹{(order || tempOrder).discount.toFixed(2)}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm line-through text-gray-400">
                    ₹{(order || tempOrder).mrp?.toFixed(2)}
                  </span>
                  <span className="text-sm font-medium">
                    ₹{((order || tempOrder).mrp - (order || tempOrder).discount)?.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600 flex items-center gap-1">
                  <Bike className="h-4 w-4 inline-block" /> Delivery charge
                  <Edit
                    onClick={() => setIsDeliveryModalOpen(true)}
                    className="w-4 h-4 cursor-pointer text-green-800 font-medium"
                  />
                </span>

                <span className="text-sm font-medium">
                  {(order || tempOrder).deliveryCharges > 0
                    ? `₹${(order || tempOrder).deliveryCharges?.toFixed(2)}`
                    : 'FREE'}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600 flex items-center gap-1">
                  <ShoppingBag className="h-4 w-4 inline-block" />
                  Platform Fees
                </span>
                <span className="text-sm font-medium">
                  ₹
                  {(order || tempOrder).platformFees !== 0
                    ? (order || tempOrder).platformFees?.toFixed(2)
                    : Number(2).toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between items-center pt-1 border-t">
                <span className="text-base font-medium">Bill total</span>
                <span className="text-base font-medium">
                  ₹{(order || tempOrder).total?.toFixed(2)}
                </span>
              </div>
            </div>
          </section>

          <section className="rounded-lg bg-white p-4">
            <h2 className="mb-4 font-medium">Delivery Details</h2>
            <div className="space-y-3">
              <div className="flex gap-3">
                <MapPin className="h-5 w-5 shrink-0 text-gray-900" />
                <p className="text-sm text-gray-600">
                  {(order || tempOrder)?.deliveryAddress || 'Not Provided'}
                </p>
              </div>
              <div className="flex gap-3">
                <Phone
                  className="h-5 w-5 shrink-0 cursor-pointer text-gray-900"
                  onClick={() => {
                    window.open(`tel:${(order || tempOrder)?.contactNo}`);
                  }}
                />
                <p className="text-sm text-gray-600">
                  {(order || tempOrder)?.contactNo || 'Not Provided'}
                </p>
              </div>
            </div>
          </section>

          <section className="rounded-lg bg-white p-4">
            <h2 className="mb-4 font-medium">Order Status</h2>
            <p className="text-sm text-gray-600">
              Status:{' '}
              <span
                className={`font-medium text-${(order || tempOrder).status === 'Completed' ? 'green' : 'blue'}-500`}
              >
                {(order || tempOrder).status === 'Completed'
                  ? 'Delivered'
                  : (order || tempOrder).status}
              </span>
              <span className="ml-2 text-red-600">
                {(order || tempOrder)?.status === 'Completed' && '(Payment Due)'}
              </span>
            </p>
            <p className="text-sm text-gray-600">
              Date:{' '}
              <span className="font-medium">
                {format(new Date((order || tempOrder).deliveryDate), 'PPP')}
              </span>
            </p>
            <p className="text-sm text-gray-600">
              Time Slot:{' '}
              <span className="font-medium">
                {!(order || tempOrder)?.expressDelivery
                  ? (order || tempOrder)?.timeSlot
                    ? formatTimeSlot((order || tempOrder)?.timeSlot)
                    : 'Not Given'
                  : (order || tempOrder)?.timeSlot}
              </span>
            </p>
            {(order || tempOrder)?.note && (
              <p className="text-sm text-gray-600">
                Note: <span className="font-medium">{(order || tempOrder)?.note}</span>
              </p>
            )}
            <p className="text-sm text-gray-600">
              Delivery Type:{' '}
              <span className="font-medium">
                {(order || tempOrder).selfPickup
                  ? 'Self Pickup'
                  : (order || tempOrder).expressDelivery
                    ? 'Express Delivery'
                    : 'Standard Delivery'}
              </span>
            </p>
          </section>
          <footer
            className={`bg-white rounded-md px-4 py-4 mb-14 ${
              (order || tempOrder)?.status === 'Paid' ||
              (order || tempOrder)?.status === 'Cancelled' ||
              (loading && 'hidden')
            }`}
          >
            {true && (
              <div className="mx-auto flex max-w-md gap-2 items-center justify-between">
                {((order || tempOrder)?.status === 'Pending' ||
                  (order || tempOrder)?.status === 'Rescheduled') && (
                  <>
                    <Button
                      className="bg-red-600 py-1 leading-5 px-4 rounded-md w-1/3 hover:bg-red-500"
                      onClick={() => setIsCancelModalOpen(true)}
                    >
                      Cancel Order
                    </Button>
                    <Button
                      className="bg-blue-600 py-1 leading-5 px-4 rounded-md w-1/3 hover:bg-blue-700"
                      onClick={() => setIsRescheduleModalOpen(true)}
                    >
                      Reschedule
                    </Button>
                  </>
                )}
                {!loading &&
                  (order || tempOrder)?.status !== 'Completed' &&
                  (order || tempOrder)?.status !== 'Cancelled' &&
                  (order || tempOrder)?.status !== 'Paid' && (
                    <Button
                      className="bg-green-600 py-1 leading-5 px-4 rounded-md w-1/3 hover:bg-green-700 text-white"
                      onClick={() => handleMarkDelivered('Completed')}
                    >
                      Mark Delivered
                    </Button>
                  )}
                {(order || tempOrder)?.status === 'Completed' && (
                  <Button
                    className="bg-green-600 py-1 leading-5 px-4 rounded-md hover:bg-green-700 text-white"
                    onClick={() => handleMarkDelivered('Paid')}
                  >
                    Complete Payment
                  </Button>
                )}
              </div>
            )}
          </footer>
        </main>
      )}
      <BottomNav />
      {isDeliveryModalOpen && (
        <dialog
          open={isDeliveryModalOpen}
          onClick={() => setIsDeliveryModalOpen(false)}
          className=" bg-black/20 fixed h-full w-full max-w-md flex items-center justify-center z-20 top-0 left-0"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="py-3 px-6 w-80 relative rounded-lg flex flex-col items-center justify-start gap-2 bg-gray-50"
          >
            <XCircle
              onClick={() => setIsDeliveryModalOpen(false)}
              className="h-6 w-6  top-2 right-2 cursor-pointer text-red-500 absolute"
            />
            <h3 className="text-lg font-medium">Delivery Charges</h3>
            <div className="flex flex-col items-start gap-1 w-full">
              <div className=" flex items-center justify-center my-4 w-full  gap-2">
                <MinusCircle
                  onClick={() => setDelivery((prev) => Number(prev) - 1)}
                  className="cursor-pointer w-8 h-8"
                />
                <input
                  type="text"
                  placeholder="Delivery Charges"
                  value={delivery}
                  onChange={(e) => setDelivery(e.target.value)}
                  className="pl-3 max-w-20 pr-2 py-2 rounded-md bg-white focus:outline-none border focus:border-black"
                />
                <PlusCircle
                  onClick={() => setDelivery((prev) => Number(prev) + 1)}
                  className="cursor-pointer w-8 h-8"
                />
              </div>
            </div>
            <button
              onClick={() => handleAddDeliveryCharge(delivery)}
              disabled={loading}
              className={`px-4 py-1 ${loading ? 'bg-slate-200 text-black' : 'bg-green-700 text-white'} rounded-md`}
            >
              Update Delivery
            </button>
          </div>
        </dialog>
      )}

      {isCancelModalOpen && (
        <div className="fixed inset-0 max-w-md mx-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Cancel Order</h2>
              <Button variant="ghost" size="icon" onClick={() => setIsCancelModalOpen(false)}>
                <X className="h-6 w-6" />
              </Button>
            </div>
            <p className="mb-4 text-sm text-gray-600">
              Please select a reason for cancelling this order:
            </p>
            <RadioGroup onValueChange={setCancelReason} className="space-y-2">
              {cancelReasons.map((reason) => (
                <div key={reason} className="flex items-center space-x-2">
                  <RadioGroupItem value={reason} id={reason} />
                  <Label htmlFor={reason} className="cursor-pointer">
                    {reason}
                  </Label>
                </div>
              ))}
            </RadioGroup>
            <div className="mt-6 flex justify-end">
              <Button
                variant="outline"
                onClick={() => setIsCancelModalOpen(false)}
                className="mr-2"
              >
                Close
              </Button>
              <Button
                className="focus-visible:ring-offset-0 focus-visible:ring-0  text-white bg-red-600 hover:bg-red-500"
                onClick={handleCancelOrder}
              >
                Cancel Order
              </Button>
            </div>
          </div>
        </div>
      )}

      {isRescheduleModalOpen && (
        <div className="fixed inset-0 z-50 max-w-md mx-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Reschedule Order</h2>
              <Button variant="ghost" size="icon" onClick={() => setIsRescheduleModalOpen(false)}>
                <X className="h-6 w-6" />
              </Button>
            </div>
            <p className="mb-4 text-sm text-gray-600">Please select a new date for your order:</p>
            <div className="flex justify-between mb-4">
              <Button
                className={`focus-visible:ring-offset-0 transition-colors duration-300 focus-visible:ring-1 ${
                  isSameDay(rescheduleDate, new Date())
                    ? 'bg-green-600 hover:bg-green-600'
                    : 'text-black border border-black'
                }`}
                onClick={() => setRescheduleDate(new Date())}
                disabled={isAllSlotsOccupiedForToday}
              >
                Today
              </Button>
              <Button
                className={`focus-visible:ring-offset-0 transition-colors duration-300 focus-visible:ring-1 ${
                  isSameDay(rescheduleDate, addDays(new Date(), 1))
                    ? 'bg-green-600 hover:bg-green-600'
                    : 'text-black border border-black'
                }`}
                onClick={() => setRescheduleDate(addDays(new Date(), 1))}
              >
                {format(addDays(new Date(), 1), 'EEE, MMM d')}
              </Button>
              <Button
                className={`focus-visible:ring-offset-0 transition-colors duration-300 focus-visible:ring-1 ${
                  isSameDay(rescheduleDate, addDays(new Date(), 2))
                    ? 'bg-green-600 hover:bg-green-600'
                    : 'text-black border border-black'
                }`}
                onClick={() => setRescheduleDate(addDays(new Date(), 2))}
              >
                {format(addDays(new Date(), 2), 'EEE, MMM d')}
              </Button>
            </div>
            <div className="mt-4">
              <label htmlFor="timeSlot" className="block text-sm font-medium text-gray-700 mb-1">
                Select Time Slot
              </label>
              <Select
                onValueChange={setSelectedTimeSlot}
                value={selectedTimeSlot}
                className="focus-visible:ring-offset-0 focus-visible:ring-1"
              >
                <SelectTrigger
                  id="timeSlot"
                  className="focus-visible:ring-offset-0 focus-visible:ring-1"
                >
                  <SelectValue placeholder="Select a time slot" />
                </SelectTrigger>
                <SelectContent className="focus-visible:ring-offset-0 focus-visible:ring-1">
                  {timeSlots.map((slot) => (
                    <SelectItem key={slot} value={slot} disabled={isSlotOccupied(slot)}>
                      {formatTimeSlot(slot)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                variant="outline"
                onClick={() => setIsRescheduleModalOpen(false)}
                className="mr-2"
              >
                Close
              </Button>
              <Button
                className="focus-visible:ring-offset-0 focus-visible:ring-0 text-white bg-green-600 hover:bg-green-500"
                onClick={handleRescheduleOrder}
                disabled={!selectedTimeSlot || loading}
              >
                {loading ? 'Rescheduling...' : 'Reschedule'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { AlertCircle, ShoppingCart, Store, X } from 'lucide-react';
import { ProductCard } from '@/components/products/ProductCard';
import { ProductDrawer } from '@/components/products/ProductDrawer';
import { useCart } from '@/hooks/useCart';
import { CartProvider } from '@/hooks/useCart';
import Loader from '@/components/common/Loader';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import { useVendor } from '@/contexts/VendorContext';
import { SEO } from '@/components/common/SEO';
import { PwaPrompt } from '../components/modals/PwaPrompt';
import { useSwipeable } from 'react-swipeable';
import axiosInstance from '../utils/axiosInstance';
import HeroHeader from './user/HeroHeader';
import { useUser } from '../contexts/UserContext';

function DashboardContent() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Vegetables');
  const [categories, setCategories] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);
  const navigate = useNavigate();
  const { cart, refreshCart } = useCart();
  const [loading, setLoading] = useState(false);
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      const currCategory = activeTab;
      const idx = categories.findIndex((i) => i === currCategory);

      if (eventData?.dir === 'Right' && idx > 0) {
        setActiveTab(categories.at(idx - 1));
      } else if (eventData?.dir === 'Left' && idx < categories?.length - 1) {
        setActiveTab(categories.at(idx + 1));
      }
    },
  });
  const { user, refreshUser } = useUser();

  const { selectedVendor, setVendors, vendors, setSelectedVendor } = useVendor();

  const fetchProducts = useCallback(
    async (controller) => {
      const userType = user?.userType;
      const selectedVendorData = JSON.parse(localStorage.getItem('selectedVendor'));

      if (!selectedVendorData && userType === 'user') {
        return;
      }

      let vendorId;
      if (userType === 'user') {
        vendorId = selectedVendorData?.vendorId;
      } else {
        vendorId = user?.id;
      }

      try {
        setLoading(true);
        const sessionToken = localStorage.getItem('sessionToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axiosInstance.get(
          `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/products?category=${activeTab}`,
          {
            headers: {
              'X-Vendor-Id': vendorId,
              sessionToken,
              refreshToken,
            },
            signal: controller.signal,
          }
        );

        const sortedProducts = response?.data?.sort((a, b) => {
          if (a?.available === b?.available) {
            return a?.productName.localeCompare(b?.productName);
          }
          return a?.available ? -1 : 1;
        });

        setProducts(sortedProducts);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [selectedVendor, activeTab, user]
  );

  const fetchNearbyVendors = async () => {
    try {
      const address = user?.addressModel[0];

      const sessionToken = localStorage.getItem('sessionToken');
      const refreshToken = localStorage.getItem('refreshToken');

      const response = await axiosInstance.get(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/near-by-vendors?plotNo=${
          address?.plotNo
        }&sector=${address?.sector}&state=${address?.state}`,
        {
          headers: {
            sessionToken,
            refreshToken,
          },
        }
      );

      setVendors(response?.data);

      const isInvited = localStorage.getItem('invite');

      const isVendorAvailable = response?.data?.find((ven) => {
        const vendorContact = ven?.contactNo?.replace('+91', '');
        return vendorContact == isInvited;
      });

      let selectedData;

      if (isVendorAvailable) {
        selectedData = {
          vendorName: isVendorAvailable?.name,
          shopName: isVendorAvailable?.shopName,
          vendorId: isVendorAvailable?.id,
          societyName: isVendorAvailable?.address?.societyName,
          contactNo: isVendorAvailable?.contactNo,
          status: isVendorAvailable?.status,
          deliveryCharge: isVendorAvailable?.deliveryCharge,
          deliveryChargeWaived: isVendorAvailable?.deliveryChargeWaived,
          minimumOrder: isVendorAvailable?.minimumOrder,
          vendorCategories: isVendorAvailable?.vendorCategories,
        };

        const apiData = [
          {
            vendorId: selectedData?.vendorId,
            societyName: selectedData?.societyName,
            vendorName: selectedData?.vendorName,
            favourite: true,
            contactNo: selectedData?.contactNo,
          },
        ];

        await axiosInstance.put(
          `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/vendors?userId=${user?.id}`,
          apiData,
          {
            headers: {
              sessionToken,
              refreshToken,
            },
          }
        );

        localStorage.removeItem('invite');

        setCategories(selectedData?.vendorCategories);
        setActiveTab(selectedData?.vendorCategories[0]);
        setSelectedVendor(selectedData);
      } else {
        const res = await axiosInstance.get(
          `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/vendors?userId=${user?.id}`,
          {
            headers: {
              sessionToken,
              refreshToken,
            },
          }
        );

        const favVendor = res.data;
        let isFavAvailable;
        if (favVendor && favVendor.length > 0) {
          isFavAvailable = response?.data?.find((ven) => {
            const vendorContact = ven.contactNo;
            return vendorContact == favVendor[0]?.contactNo;
          });
        }

        if (isFavAvailable) {
          selectedData = {
            vendorName: isFavAvailable?.name,
            shopName: isFavAvailable?.shopName,
            vendorId: isFavAvailable?.id,
            societyName: isFavAvailable?.address?.societyName,
            contactNo: isFavAvailable?.contactNo,
            status: isFavAvailable?.status,
            deliveryCharge: isFavAvailable?.deliveryCharge,
            deliveryChargeWaived: isFavAvailable?.deliveryChargeWaived,
            minimumOrder: isFavAvailable?.minimumOrder,
            vendorCategories: isFavAvailable?.vendorCategories,
          };
        } else {
          selectedData = {
            vendorName: response?.data[0]?.name,
            shopName: response?.data[0]?.shopName,
            vendorId: response?.data[0]?.id,
            societyName: response?.data[0]?.address?.societyName,
            contactNo: response?.data[0]?.contactNo,
            status: response?.data[0]?.status,
            deliveryCharge: response?.data[0]?.deliveryCharge,
            deliveryChargeWaived: response?.data[0]?.deliveryChargeWaived,
            minimumOrder: response?.data[0]?.minimumOrder,
            vendorCategories: response?.data[0]?.vendorCategories,
          };

          const apiData = [
            {
              vendorId: selectedData?.vendorId,
              societyName: selectedData?.societyName,
              vendorName: selectedData?.vendorName,
              favourite: true,
              contactNo: selectedData?.contactNo,
            },
          ];

          await axiosInstance.put(
            `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/vendors?userId=${user?.id}`,
            apiData,
            {
              headers: {
                sessionToken,
                refreshToken,
              },
            }
          );
        }

        setCategories(selectedData?.vendorCategories);
        setActiveTab(selectedData?.vendorCategories[0]);
        setSelectedVendor(selectedData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    refreshCart();
    if (user?.userType === 'user') {
      fetchNearbyVendors();
    } else if (user?.userType === 'vendor') {
      setCategories(user?.vendorCategories);
      setActiveTab(user?.vendorCategories[0]);
    } else {
      const contactNo = localStorage.getItem('contactNo');
      console.log(contactNo);
      refreshUser(contactNo);
    }
  }, [user]);

  useEffect(() => {
    const controller = new AbortController();

    if (categories && categories?.length > 0 && user) {
      fetchProducts(controller);
    }
    return () => controller.abort();
  }, [user, activeTab, categories]);

  useEffect(() => {
    const handleBeforeInstallPrompt = async (e) => {
      // e.preventDefault();
      setInstallPrompt(e); // Store the event for later use

      const hasUserDismissed = localStorage.getItem('pwaPromptDismissed');
      if (!hasUserDismissed) {
        setShowPrompt(true);
      }
    };
    window.scrollTo(0, 0);

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  return (
    <div className="min-h-screen sm:border-l sm:border-r bg-white">
      <PwaPrompt
        installPrompt={installPrompt}
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
        setInstallPrompt={setInstallPrompt}
      />
      <SEO
        title="Store - Subziwale Your Trusted Partner for Fruits & Vegetables"
        description="Order our fresh selection of fruits, vegetables, and dairy products. High-quality produce delivered to your doorstep in Dwarka, Delhi."
        canonicalUrl="https://www.subziwale.com/"
        ogImage="/images/logo.png"
      />
      <div className="max-w-md mx-auto">
        <HeroHeader setCategories={setCategories} />
        <div>
          <div
            className={`sticky top-14  z-10 transition-all duration-300 bg-white pb-1 ${user?.userType === 'vendor' && 'pt-2'} px-2`}
          >
            {user?.userType === 'user' && selectedVendor && (
              <div className="bg-white animate-fadeIn">
                <div className="px-4 py-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div className="p-2 bg-green-50 rounded-full">
                        <Store className="h-5 w-5 text-green-600" />
                      </div>
                      <div>
                        <h2 className="font-medium line-clamp-1 text-gray-900">
                          {selectedVendor?.shopName || `${selectedVendor?.vendorName}'s Store`}
                        </h2>
                        {/* <p className="text-sm text-gray-500">Order fresh produce online from</p> */}
                      </div>
                    </div>
                    {selectedVendor.status === 'ONLINE' && (
                      <span className="px-2 py-1 bg-green-50 text-green-700 text-xs font-medium rounded-full">
                        Open
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* <Header cart={cart} id="profile-icon" /> */}
            <Tabs
              defaultValue={activeTab}
              onValueChange={(e) => {
                setActiveTab(e);
              }}
              className={`w-full`}
            >
              <TabsList className="w-fit h-fit bg-transparent px-1 py-0 gap-1">
                {categories?.map((tab) => (
                  <TabsTrigger
                    key={tab}
                    value={tab}
                    onClick={() => {
                      setActiveTab(tab);
                    }}
                    data-state={tab === activeTab ? 'active' : 'inactive'}
                    className="flex-1 py-1 px-1 items-center justify-center w-fit flex-row data-[state=active]:text-white data-[state=active]:shadow-none data-[state=active]:bg-transparent transition-all duration-200 rounded-none"
                  >
                    <div key={tab} className="">
                      <div
                        className={`${activeTab === tab ? 'bg-green-600 text-white' : 'bg-white text-black'} rounded-md shadow-sm px-3 pt-0 pb-2  flex flex-col items-center border transition-all duration-300 hover:shadow-sm`}
                      >
                        <div className={` px-2 pt-2 pb-1 rounded-full mb-1`}>
                          <img loading="lazy" src={`/${tab}.png`} alt={tab} className="w-8 h-8" />
                        </div>
                        <span className="text-xs font-medium">{tab}</span>
                      </div>
                    </div>
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </div>
          <main id="product-item" className="relative mx-auto px-4 py-2 pb-20" {...handlers}>
            <div className="grid grid-cols-2 gap-4">
              {loading || categories?.length === 0 ? (
                <div className="absolute top-10 z-20 max-w-md h-full left-1/2 -translate-x-1/2  w-full flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {user?.userType === 'user' &&
                    vendors?.length > 0 &&
                    products?.map((product, index) => (
                      <ProductCard
                        key={product.productId + index + ''}
                        product={product}
                        onSelect={handleProductSelect}
                        category={activeTab}
                      />
                    ))}
                  {user?.userType === 'vendor' && (
                    <div className="pt-16 grid col-span-2 grid-cols-2 gap-4">
                      {products?.map((product) => (
                        <ProductCard
                          key={product.productId}
                          product={product}
                          onSelect={handleProductSelect}
                          category={activeTab}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </main>
        </div>
        {user?.userType === 'vendor' ||
          (user?.userType === 'user' && vendors && vendors?.length > 0 && (
            <ProductDrawer
              product={selectedProduct}
              isOpen={isDrawerOpen}
              onClose={handleDrawerClose}
            />
          ))}
        {user?.userType === 'user' && vendors?.length === 0 && (
          <ProductDrawer
            product={selectedProduct}
            isOpen={isDrawerOpen}
            onClose={handleDrawerClose}
            isCartBlocked={true}
          />
        )}
        {user?.userType === 'user' && cart.length > 0 && (
          <div className="fixed max-w-md mx-auto bottom-16 left-4 right-4">
            <Button
              className="w-10/12 mx-auto flex bg-[#39c55e] hover:bg-[#2ea34d] text-white shadow-lg transition-all duration-200"
              onClick={() => navigate('/cart')}
            >
              <ShoppingCart className="mr-2 h-5 w-5" />
              {cart.length} {cart.length === 1 ? 'item' : 'items'} | Go to Cart
            </Button>
          </div>
        )}
      </div>

      <BottomNav page="dashboard" />
    </div>
  );
}

export default function Dashboard() {
  return (
    <CartProvider>
      <DashboardContent />
    </CartProvider>
  );
}

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  ChevronLeft,
  Home,
  MapPin,
  User,
  ShoppingBag,
  Settings,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import { Input } from '@/components/ui/input';
import { useUser } from '../../contexts/UserContext';
import axiosInstance from '../../utils/axiosInstance';

const VendorProfile = () => {
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState('');
  const [minimumOrderValue, setMinimumOrderValue] = useState('');
  const [addDeliveryCharges, setAddDeliveryCharges] = useState(false);
  const { user, refreshUser } = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (user) {
      console.log(user);
      setAddDeliveryCharges(user?.deliveryChargeWaived);
      setMinimumOrderValue(user?.minimumOrder);
      setDeliveryCharges(user?.deliveryCharge);
      setName(user?.name);
      setEmail(user?.emailId);
    }
  }, [user]);

  const fetchData = async () => {
    const phone = localStorage.getItem('contactNo');
    if (!phone) {
      return;
    }
    refreshUser(phone);
  };

  const handleLogout = () => {
    logout();
  };

  const handleUpdate = async () => {
    setLoading(true);

    const apiData = {
      name,
      shopName: user?.shopName,
      emailId: email,
      contactNo: user?.contactNo,
      alternateContact: user?.alternateContact,
      minimumOrder: minimumOrderValue,
      deliveryCharge: deliveryCharges,
      deliveryChargeWaived: addDeliveryCharges,
      categoryIds: [],
    };

    try {
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/vendor`,
        apiData,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );
      setIsNameModalOpen(false);
      toast.success('Profile updated successfully');
      fetchData();
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const handleOutOfStation = async () => {
    try {
      if (!user?.id) {
        window.location.reload();
        return;
      }

      let status;
      if (user?.status === 'Approved') {
        status = 'OutOfStation';
      } else if (user?.status == 'OutOfStation') {
        status = 'Approved';
      } else {
        return;
      }

      setLoading(true);
      const { data } = await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/admin/dashboard/api/v1/vendor/status?vendorId=${user?.id}&status=${status}`,
        {},
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      setName(data?.name);
      setEmail(data?.emailId);
      setDeliveryCharges(data?.deliveryCharge);
      setMinimumOrderValue(data?.minimumOrder);
      setAddDeliveryCharges(data?.deliveryChargeWaived);

      fetchData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col max-w-md mx-auto sm:border-l sm:border-r min-h-screen bg-gray-100">
      <header className="sticky top-0 z-10 bg-green-800 text-white">
        <div className="container mx-auto px-4 py-4 flex items-center">
          <ChevronLeft onClick={() => navigate(-1)} className="h-6 w-6 cursor-pointer" />
          <h1 className="ml-2 text-xl font-semibold">My Account</h1>
          <Home onClick={() => navigate('/')} className="h-6 w-6 cursor-pointer ml-auto" />
        </div>
      </header>

      <main className="flex-grow container mx-auto pb-16 pt-4 px-4 space-y-2">
        <Card className="bg-white shadow-sm relative">
          <CardHeader className="px-4 pt-2 pb-3">
            <CardTitle className="text-lg font-semibold flex items-center">
              <div className="flex gap-1 items-center">
                <User className="mr-1  h-5 w-5" /> Personal Information
              </div>
              <button
                onClick={handleOutOfStation}
                className={`${user?.status === 'Approved' ? 'bg-red-500' : 'bg-green-600'} text-white px-4 py-1 block ml-auto text-sm rounded-md`}
                disabled={loading}
              >
                {!loading
                  ? user?.status === 'Approved'
                    ? 'Out Of Station'
                    : 'Back to work'
                  : 'Loading'}
              </button>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2 ">
              <p>
                <span className="font-medium">Name:</span> {user?.name}
              </p>
              <p>
                <span className="font-medium">Email:</span> {user?.emailId || 'Not Provided'}
              </p>
              <p>
                <span className="font-medium">Contact:</span> {user?.contactNo}
              </p>
              <Button
                onClick={() => setIsNameModalOpen(true)}
                variant="outline"
                className="mt-2 w-full"
              >
                Edit Profile
              </Button>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-white shadow-sm">
          <CardHeader className="px-4 pt-2 space-y-0 pb-3 flex-row items-center justify-between">
            <CardTitle className="text-lg  font-semibold flex items-center">
              <ShoppingBag className="mr-2 h-5 w-5" /> Service Areas
            </CardTitle>
            <div className="text-base font-medium mt-0">
              Total {user?.serviceArea?.length} areas
            </div>
          </CardHeader>
          <CardContent>
            <div className="px-3 pb-3">
              <ScrollArea className="h-fit max-h-44  overflow-y-auto">
                {user?.serviceArea?.map((item, idx) => {
                  return (
                    <p
                      className="border-b-[1.4px] border-dashed border-slate-400 last:pt-1 text-balance pb-1 last:border-none"
                      key={idx}
                    >
                      {item?.societyName}, Plot/Pocket No {item?.plotNo}, Secor {item?.sector}
                    </p>
                  );
                })}
              </ScrollArea>
            </div>
            <Button
              onClick={() => navigate('/vendor/service/edit')}
              variant="outline"
              className="w-full"
            >
              Edit Service Areas
            </Button>
          </CardContent>
        </Card>

        <Card className="bg-white shadow-sm">
          <CardHeader className="px-4 pt-2 pb-3">
            <CardTitle className="text-lg font-semibold flex items-center">
              <Settings className="mr-2 h-5 w-5" /> Order Settings
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div>
                <label htmlFor="minimum-order" className="block text-sm font-medium text-gray-700">
                  Minimum Order Value (₹)
                </label>
                <Input
                  id="minimum-order"
                  type="number"
                  value={minimumOrderValue}
                  onChange={(e) => setMinimumOrderValue(e.target.value)}
                  className="mt-1"
                  placeholder="Enter minimum order value"
                  required
                  min="0"
                  step="0.01"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Add delivery charges to all products?
                </label>
                <div className="mt-1 flex justify-start space-x-4">
                  <button
                    type="button"
                    onClick={() => setAddDeliveryCharges(true)}
                    variant={addDeliveryCharges ? 'default' : 'outline'}
                    className={`${addDeliveryCharges ? 'bg-green-600 text-white' : 'bg-gray-200'} px-4 py-1 rounded-sm `}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => setAddDeliveryCharges(false)}
                    variant={!addDeliveryCharges ? 'default' : 'outline'}
                    className={`${!addDeliveryCharges ? 'bg-green-600 text-white' : 'bg-gray-200'} px-4 py-1 rounded-sm `}
                  >
                    No
                  </button>
                </div>
              </div>
              <div>
                <label
                  htmlFor="delivery-charges"
                  className="block text-sm font-medium text-gray-700"
                >
                  Delivery Charges (₹)
                </label>
                <Input
                  id="delivery-charges"
                  type="number"
                  value={deliveryCharges}
                  onChange={(e) => setDeliveryCharges(e.target.value)}
                  className="mt-1"
                  placeholder="e.g, 0"
                  required
                  min="0"
                  step="0.01"
                />
              </div>
            </div>
            <button
              onClick={handleUpdate}
              className="mt-2 w-fit rounded-md text-white bg-green-600 block mx-auto py-1 px-6 "
            >
              Update Settings
            </button>
          </CardContent>
        </Card>

        <Card className="bg-white shadow-sm">
          <CardHeader className="px-4 pt-2 pb-3">
            <CardTitle className="text-lg font-semibold flex items-center">
              <MapPin className="mr-2 h-5 w-5" /> Address
            </CardTitle>
          </CardHeader>
          <CardContent>
            {user?.address ? (
              <div className="space-y-1">
                <p>
                  {user?.address?.unitNo} {user?.address?.societyName}, Plot/Pocket No{' '}
                  {user?.address?.plotNo}
                </p>
                <p>
                  Sector {user?.address?.sector}, {user?.address?.locality}, {user?.address?.city}
                </p>
                <p>
                  {user?.address?.state}, {user?.address?.pinCode}, {user?.address?.country}
                </p>
              </div>
            ) : (
              <p>Address not provided</p>
            )}
          </CardContent>
        </Card>

        <AboutUsCard />

        <Button onClick={handleLogout} variant="destructive" className="w-fit block mx-auto">
          Logout
        </Button>
      </main>

      <BottomNav />

      {isNameModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-sm">
            <h2 className="text-xl font-semibold mb-4">Update Profile</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <Input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="mt-1"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1"
                />
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-6">
              <Button variant="outline" onClick={() => setIsNameModalOpen(false)}>
                Cancel
              </Button>
              <Button
                className="bg-green-700 hover:bg-green-700"
                onClick={handleUpdate}
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const AboutUsCard = () => {
  return (
    <Card className="bg-white shadow-sm relative">
      <CardHeader className="pt-3 pb-3 px-4">
        <CardTitle className="text-lg font-semibold flex items-center">
          <div className="flex items-center justify-center">
            <a
              href="https://x.com/subziwale"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Twitter className="h-6 w-6 text-gray-700" />
            </a>
            {/* <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Linkedin className="h-6 w-6 text-gray-700" />
            </a> */}
            <a
              href="https://www.facebook.com/profile.php?id=61572946807977"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Facebook className="h-6 w-6 text-gray-700" />
            </a>
            <a
              href="https://www.instagram.com/subziwale/"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Instagram className="h-6 w-6 text-gray-700" />
            </a>
          </div>
          <button
            variant="outline"
            className="w-fit px-6 py-2 bg-green-700 text-white rounded-md text-sm ml-auto block"
            onClick={() => window.open('https://about.subziwale.com/', '_blank')}
          >
            About Us
          </button>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

export default VendorProfile;

'use client';

import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { fetchSocieties } from '@/constant/constant';
import { useAuth } from '../../contexts/AuthContext';
import { Info, Search } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import { Alert, AlertDescription } from '@/components/ui/alert';

const indianStates = [
  'Delhi',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Jammu and Kashmir',
  'Ladakh',
  'Lakshadweep',
  'Puducherry',
];

export default function AuthAddress() {
  const [address, setAddress] = useState({
    unitNo: '',
    societyName: '',
    plotNo: '',
    sector: '',
    locality: '',
    type: 'home',
    city: '',
    district: '',
    state: '',
    country: 'India',
    contactNo: '',
    pinCode: '',
    defaultAdd: true,
  });
  const [contactNo, setContactNo] = useState('');
  const navigate = useNavigate();
  const [residenceType, setResidenceType] = useState('society');
  const [societyQuery, setSocietyQuery] = useState('');
  const [societies, setSocieties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [completeAddress, setCompleteAddress] = useState('');
  const { logout } = useAuth();
  const societySearchRef = useRef(null);
  const NotFoundRef = useRef(null);
  const params = useParams();
  const { user } = useUser();

  useEffect(() => {
    if (residenceType === 'society' && societyQuery.length >= 3) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const results = await fetchSocieties(societyQuery);
          setSocieties(results.length > 0 ? results : []);
        } catch (error) {
          console.error('Error fetching societies:', error);
          toast.error('Failed to fetch societies');
          setSocieties([]);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      setSocieties([]);
    }
  }, [societyQuery, residenceType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => {
      const newAddress = { ...prev, [name]: value };
      updateCompleteAddress(newAddress);
      return newAddress;
    });
  };

  const handleSocietySelect = (society) => {
    const newAddress = {
      ...address,
      ...society,
      unitNo: address.unitNo || '',
      contactNo: address.contactNo || '',
      id: '',
      type: address.type || 'home',
    };
    setAddress(newAddress);
    setSocietyQuery(society.societyName);
    setSocieties([]);
    setIsSelected(true);
    updateCompleteAddress(newAddress);
  };

  const updateCompleteAddress = (addressData) => {
    if (residenceType === 'society') {
      const addressParts = [
        addressData.unitNo,
        addressData.societyName,
        'Plot/Pocket No ' + addressData.plotNo,
        'Sector ' + addressData.sector,
        addressData.locality,
        addressData.city,
        addressData.state,
        addressData?.district,
        addressData.pinCode,
        addressData.country,
      ].filter(Boolean);
      setCompleteAddress(addressParts.join(', '));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      residenceType === 'society' &&
      (!address.societyName || address.societyName === 'Not Found')
    ) {
      toast.error('Please select a valid society');
      return;
    }

    try {
      setIsSubmiting(true);
      const sessionToken = localStorage.getItem('sessionToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const apiRes = await axios.get(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/near-by-vendors`,
        {
          params: {
            plotNo: address.plotNo,
            sector: address.sector,
            state: address.state,
          },
          headers: { sessionToken, refreshToken },
        }
      );

      if (apiRes.data.length === 0) {
        setAddress({
          unitNo: '',
          societyName: '',
          plotNo: '',
          sector: '',
          locality: '',
          type: 'home',
          city: '',
          district: '',
          state: '',
          country: 'India',
          contactNo: '',
          pinCode: '',
          defaultAdd: true,
        });
        toast.info(
          "Thanks for Showing Interest! No seller is available in your area yet, but we're expanding. We'll notify you once a seller is available!"
        );
        logout();
        return;
      }

      const apiData = {
        name: '',
        emailId: '',
        contactNo: '+91' + contactNo,
        userType: 'user',
      };

      const userRes = await axios.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user`,
        apiData,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      localStorage.setItem('contactNo', userRes?.data?.contactNo);

      const addressResponse = await axios.post(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/address`,
        { ...address, defaultAdd: true },
        { headers: { 'X-User-Id': userRes?.data?.id, sessionToken, refreshToken } }
      );

      const token = localStorage.getItem('sessionToken');
      localStorage.setItem('token', token);
      localStorage.setItem('userRole', userRes?.data?.userType);

      navigate(`/`);
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data ||
          'Sorry! There was an error while updating your records. Please try again.'
      );
    } finally {
      setIsSubmiting(false);
    }
  };

  useEffect(() => {
    const phone = params?.phone;
    if (phone) {
      setContactNo(phone);
      localStorage.setItem('contactNo', phone);
      setAddress((prev) => ({
        ...prev,
        contactNo: phone,
      }));
    } else {
      logout();
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (societySearchRef.current && !societySearchRef.current.contains(event.target)) {
        setSocieties([]);

        setSocietyQuery('');
        setIsSelected(false);
      }

      if (NotFoundRef.current && !NotFoundRef.current.contains(event.target)) {
        setSocietyQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen">
      <div className="max-w-md mx-auto bg-white border-l border-r overflow-hidden">
        <div className="flex items-center justify-start flex-col min-h-screen">
          <header className="bg-[#3d6b2f] w-full py-4 px-4 text-white">
            <div className="flex items-center gap-2 mb-1">
              <img loading="lazy" src="/images/leafsubzi.png" alt="leaf" className="w-32" />
            </div>
          </header>
          {isSubmiting && (
            <div className="fixed top-0 z-50 max-w-md bg-black/10 h-full left-1/2 -translate-x-1/2  w-full flex items-center justify-center">
              <div className="pulseanimate mt-20">
                <img loading="lazy" src="/images/leaf2.png" className="w-10" alt="loader" />
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-2 py-4 px-6 w-full">
            {/* Residence Type */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Residence Type</label>
              <div className="flex space-x-4">
                <label htmlFor="society" className="inline-flex cursor-pointer items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="residenceType"
                    value="society"
                    id="society"
                    checked={residenceType === 'society'}
                    onChange={(e) => {
                      setAddress({
                        unitNo: address?.unitNo || '',
                        societyName: '',
                        plotNo: '',
                        sector: '',
                        locality: '',
                        type: 'home',
                        city: '',
                        district: '',
                        state: '',
                        country: 'India',
                        contactNo: contactNo,
                        pinCode: '',
                      });
                      setSocietyQuery('');
                      setResidenceType(e.target.value);
                      setCompleteAddress('');
                    }}
                  />
                  <span className="ml-2">Society</span>
                </label>
                <label htmlFor="complex" className="inline-flex cursor-pointer items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="residenceType"
                    value="complex"
                    id="complex"
                    checked={residenceType === 'complex'}
                    onChange={(e) => {
                      setAddress({
                        unitNo: address?.unitNo || '',
                        societyName: '',
                        plotNo: '',
                        sector: '',
                        locality: '',
                        type: 'home',
                        city: '',
                        district: '',
                        state: '',
                        country: 'India',
                        contactNo: contactNo,
                        pinCode: '',
                      });
                      setSocietyQuery('');
                      setResidenceType(e.target.value);
                      setCompleteAddress('');
                    }}
                  />
                  <span className="ml-2">Complex / Landmark</span>
                </label>
              </div>
            </div>

            {/* Address Fields */}
            {residenceType === 'society' ? (
              <div className="relative" ref={societySearchRef}>
                <div className="flex items-center justify-center gap-2">
                  <div className="w-full">
                    <label
                      className="block relative w-fit text-sm font-medium text-gray-700"
                      htmlFor="societyName"
                    >
                      Search Societies
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <div className="relative">
                      <div className="absolute right-2 h-full flex items-center justify-center">
                        <Search className="h-5 w-5" />
                      </div>
                      <input
                        type="text"
                        id="societyName"
                        name="societyName"
                        value={societyQuery}
                        onChange={(e) => {
                          setSocietyQuery(e.target.value);
                          setIsSelected(false);
                        }}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                        placeholder="Type to search societies..."
                      />
                    </div>
                  </div>
                </div>
                {isLoading && (
                  <div className="absolute right-3 top-1/2 transform ">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900"></div>
                  </div>
                )}
                {societies?.length > 0 && !isSelected && (
                  <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-60 overflow-auto">
                    {societies.map((society, index) => (
                      <li
                        key={index}
                        className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSocietySelect(society)}
                      >
                        <p className="text-black">{society.societyName}</p>
                        <p className="text-gray-500 text-sm">
                          Plot/Pocket No {society?.plotNo}, Sector {society?.sector}
                        </p>
                      </li>
                    ))}
                  </ul>
                )}
                {societies?.length === 0 &&
                  !isLoading &&
                  !isSelected &&
                  societyQuery.length > 3 && (
                    <div
                      ref={NotFoundRef}
                      className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-60 overflow-auto"
                    >
                      <p className="px-3 py-2">❌ No Society Found</p>
                    </div>
                  )}
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="societyName"
                      className="block relative w-fit text-sm font-medium text-gray-700"
                    >
                      Complex / Landmark
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="text"
                      id="societyName"
                      name="societyName"
                      value={address.societyName}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="plotNo"
                      className="block w-fit relative text-sm font-medium text-gray-700"
                    >
                      Plot/Pocket No
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="text"
                      id="plotNo"
                      name="plotNo"
                      value={address.plotNo}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="sector"
                      className="block w-fit relative text-sm font-medium text-gray-700"
                    >
                      Sector
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="text"
                      id="sector"
                      name="sector"
                      placeholder="13"
                      value={address.sector}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="locality"
                      className="block w-fit relative text-sm font-medium text-gray-700"
                    >
                      Locality
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="text"
                      id="locality"
                      name="locality"
                      placeholder="Dwarka"
                      value={address.locality}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="city"
                      className="block w-fit relative text-sm font-medium text-gray-700"
                    >
                      City
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={address.city}
                      onChange={handleChange}
                      placeholder="Delhi"
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="state"
                      className="block w-fit relative text-sm font-medium text-gray-700"
                    >
                      State
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <select
                      id="state"
                      name="state"
                      value={address.state}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                    >
                      <option value="">Select State</option>
                      {indianStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="district"
                      className="block w-fit relative text-sm font-medium text-gray-700"
                    >
                      District (Optional)
                    </label>
                    <input
                      type="text"
                      id="district"
                      name="district"
                      placeholder="district"
                      value={address.district}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="pinCode"
                      className="block w-fit relative text-sm font-medium text-gray-700"
                    >
                      Pin Code
                      <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="text"
                      id="pinCode"
                      name="pinCode"
                      value={address.pinCode}
                      onChange={handleChange}
                      placeholder="262021"
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                      required
                      pattern="[0-9]{6}"
                      inputMode="numeric"
                      maxLength="6"
                      minLength={6}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="country"
                    className="block w-fit relative text-sm font-medium text-gray-700"
                  >
                    Country
                    <span className="absolute -top-1 -right-2 text-red-400 text-base">*</span>
                  </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    value={address.country}
                    onChange={handleChange}
                    placeholder="India"
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                    required
                  />
                </div>
              </>
            )}

            {/* Complete Address Display */}
            {residenceType === 'society' && address?.societyName && (
              <div className="col-span-2">
                <label
                  htmlFor="completeAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Complete Address
                </label>
                <textarea
                  id="completeAddress"
                  value={completeAddress}
                  readOnly
                  className="mt-1 block w-full px-3 py-2  border border-gray-300 rounded-md shadow-sm focus:outline-none bg-gray-200 focus:ring-[#3d6b2f] focus:border-[#3d6b2f] sm:text-sm"
                  rows={3}
                />
              </div>
            )}

            {/* Submit Button */}
            <div className="w-full flex items-center gap-2 mt-6 pt-6">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#3d6b2f] hover:bg-[#3d6b2f]/90 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={
                  (residenceType === 'society' && !address.societyName) || !address.contactNo
                }
              >
                Finish
              </button>
            </div>
          </form>

          <div className="w-full px-6">
            <Alert className="bg-green-50  border-green-200 text-green-800">
              <Info className="h-4 w-4 text-green-600" />
              <AlertDescription className="text-sm">
                Currently, our services are available only in Dwarka, Delhi. We're working on
                expanding to more areas soon!
              </AlertDescription>
            </Alert>
          </div>
        </div>
      </div>
    </div>
  );
}

'use client';

import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import {
  ChevronLeft,
  Home,
  MapPin,
  User,
  Edit,
  Mail,
  Download,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import { VendorSelect } from '../../components/common/VendorSelect';
import { SEO } from '@/components/common/SEO';
import emailjs from '@emailjs/browser';
import { useUser } from '../../contexts/UserContext';
import axiosInstance from '../../utils/axiosInstance';
import { isIOS, isStandalone } from '@/utils/PWAUtils';
import AboutUsModal from '@/components/modals/AboutUsModal';

const Profile = () => {
  const { user, refreshUser } = useUser();
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [isIosModalOpen, setIsIosModalOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [installPrompt, setInstallPrompt] = useState(null);

  const fetchData = async () => {
    const phone = localStorage.getItem('contactNo');
    if (!phone) {
      logout();
      return;
    }
    refreshUser(phone);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const phone = localStorage.getItem('contactNo');
    console.log(phone);

    if (!phone) {
      logout();
      return;
    }
    refreshUser(phone);

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setInstallPrompt(e);
    });
  }, []);

  return (
    <div className="flex flex-col max-w-md mx-auto sm:border-l sm:border-r min-h-screen bg-gray-100">
      <SEO
        title="Profile - Subziwale Your Trusted Partner for Fruits & Vegetables"
        description="Browse our fresh selection of fruits, vegetables, and dairy products. High-quality produce delivered to your doorstep in Dwarka, Delhi."
        canonicalUrl="https://www.subziwale.com/profile"
        ogImage="/images/logo.png"
      />
      <header className="sticky top-0 z-10 bg-green-800 text-white">
        <div className="container mx-auto px-4 py-4 flex items-center">
          <ChevronLeft onClick={() => navigate(-1)} className="h-6 w-6 cursor-pointer" />
          <h1 className="ml-2 text-xl font-semibold">My Account</h1>
          <Home onClick={() => navigate('/')} className="h-6 w-6 cursor-pointer ml-auto" />
        </div>
      </header>

      <main className="flex-grow container mx-auto pb-16 pt-4 px-4 space-y-4">
        <PersonalInfoCard
          user={user}
          setIsIosModalOpen={setIsIosModalOpen}
          setIsNameModalOpen={setIsNameModalOpen}
          installPrompt={installPrompt}
          setInstallPrompt={setInstallPrompt}
        />
        <VendorSwitch refresh={state?.refresh} />

        <AddressCard addresses={user?.addressModel} navigate={navigate} />

        <EmailForm />

        <AboutUsCard />

        <Button onClick={handleLogout} variant="destructive" className="block mx-auto">
          Logout
        </Button>
      </main>

      <BottomNav />

      <NameUpdateModal
        isOpen={isNameModalOpen}
        setIsOpen={setIsNameModalOpen}
        fetchData={fetchData}
        userData={user}
      />

      <Dialog open={isIosModalOpen} onOpenChange={setIsIosModalOpen}>
        <DialogContent className="sm:max-w-md w-auto max-h-screen p-0 overflow-hidden">
          <img
            src="/iostut.png"
            alt="iOS installation tutorial"
            className="h-auto w-full rounded-lg"
            loading="lazy"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const VendorSwitch = ({ refresh }) => {
  return (
    <Card className="bg-white shadow-sm relative">
      <CardHeader className="py-3 px-4">
        <CardTitle className="text-lg font-semibold flex items-center">
          <User className="mr-2 h-5 w-5" /> Choose your favourite Subziwala
        </CardTitle>
      </CardHeader>
      <CardContent className="py-1 pb-5 px-4">
        <VendorSelect refresh={refresh} />
      </CardContent>
    </Card>
  );
};

const EmailForm = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        import.meta.env?.VITE_EMAILJS_SERVICE_ID,
        import.meta.env?.VITE_EMAILJS_TEMPLATE_ID,
        form.current,
        {
          publicKey: import.meta.env?.VITE_EMAILJS_API_KEY,
        }
      )
      .then(
        () => {
          toast.success('Email Sent ✅');
          setLoading(false);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setLoading(false);
        }
      )
      .catch((error) => {
        console.error(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card className="bg-white shadow-sm relative">
      <CardHeader className="py-3 px-4">
        <CardTitle className="text-lg font-semibold flex items-center">
          <Mail className="mr-2 h-5 w-5" /> Contact Us
        </CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={sendEmail} ref={form} className="flex items-start pt-2 flex-col">
          <div className="mb-2 w-full">
            <label htmlFor="name" className="text-base">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="user_name"
              defaultValue={user?.name}
              className="focus:outline-none w-full border focus:border-black px-3 py-1 rounded-md"
            />
          </div>
          <div className="mb-2 w-full">
            <label className="text-base" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="user_email"
              defaultValue={user?.emailId}
              className="focus:outline-none w-full border focus:border-black px-3 py-1 rounded-md"
            />
          </div>
          <div className="mb-1 w-full">
            <label className="text-base" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="3"
              className="focus:outline-none w-full border focus:border-black px-3 py-1 rounded-md"
            ></textarea>
          </div>
          <button
            type="submit"
            value="Submit"
            disabled={loading}
            className="px-8 rounded-md block  ml-auto py-2 bg-green-700 text-white "
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </CardContent>
    </Card>
  );
};

const AboutUsCard = () => {
  return (
    <Card className="bg-white shadow-sm relative">
      <CardHeader className="pt-3 pb-3 px-4">
        <CardTitle className="text-lg font-semibold flex items-center">
          <div className="flex items-center justify-center">
            <a
              href="https://x.com/subziwale"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Twitter className="h-6 w-6 text-gray-700" />
            </a>
            {/* <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Linkedin className="h-6 w-6 text-gray-700" />
            </a> */}
            <a
              href="https://www.facebook.com/profile.php?id=61572946807977"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Facebook className="h-6 w-6 text-gray-700" />
            </a>
            <a
              href="https://www.instagram.com/subziwale/"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <Instagram className="h-6 w-6 text-gray-700" />
            </a>
          </div>
          <button
            variant="outline"
            className="w-fit px-6 py-2 bg-green-700 text-white rounded-md text-sm ml-auto block"
            onClick={() => window.open('https://about.subziwale.com/', '_blank')}
          >
            About Us
          </button>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

const PersonalInfoCard = ({
  user,
  setIsIosModalOpen,
  setIsNameModalOpen,
  installPrompt,
  setInstallPrompt,
}) => {
  const installPwa = async () => {
    if (isIOS()) {
      setIsIosModalOpen(true);
      return;
    }
    if (!installPrompt) {
      if (isIOS()) {
        setIsIosModalOpen(true);
        return;
      }
      return;
    }

    try {
      const choiceResult = await installPrompt.prompt();
      if (choiceResult.outcome === 'accepted') {
        localStorage.setItem('pwaPromptDismissed', 'true');
      } else {
        toast.info(
          'For a smoother experience, install the app anytime from your browser settings or profile.'
        );
      }
    } catch (error) {
      console.error('Error installing PWA:', error);
    }

    setInstallPrompt(null);
  };

  return (
    <Card className="bg-white shadow-sm relative">
      <CardHeader className="py-3 px-4">
        <CardTitle className="text-lg font-semibold flex items-center justify-between">
          <div className="flex items-center">
            <User className="mr-2 h-5 w-5" /> Personal Information
          </div>
          {!isStandalone() && (
            <Button
              className="text-green-700 px-2 hover:bg-green-50 font-medium"
              onClick={installPwa}
            >
              <Download className="h-4 w-4 mr-1" />
              Install App
            </Button>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <p>
            <span className="font-medium">Name:</span> {user?.name}
          </p>
          <p>
            <span className="font-medium">Email:</span> {user?.emailId || 'Not Provided'}
          </p>
          <p>
            <span className="font-medium">Contact:</span> {user?.contactNo}
          </p>
          <Button
            onClick={() => setIsNameModalOpen(true)}
            variant="outline"
            className="mt-2 w-full"
          >
            Edit Profile
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const AddressCard = ({ addresses, navigate }) => {
  return (
    <Card className="bg-white shadow-sm">
      <CardHeader className="py-3 px-4">
        <CardTitle className="text-lg font-semibold flex items-center">
          <MapPin className="mr-2 h-5 w-5" /> Addresses
        </CardTitle>
      </CardHeader>
      <CardContent>
        {addresses && addresses.length > 0 ? (
          addresses.map((address, index) => (
            <div key={address.id} className="mb-4 last:mb-0">
              <div className="flex justify-between items-start">
                <p className="font-medium">
                  {address.type.charAt(0).toUpperCase() + address.type.slice(1)}
                  {address.defaultAdd && ' (Default)'}
                </p>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => navigate('/address', { state: { address } })}
                >
                  <Edit className="h-4 w-4" />
                </Button>
              </div>
              <p>
                {address?.unitNo}, {address?.societyName}, Plot/Pocket No {address?.plotNo}
              </p>
              <p>
                Sector {address?.sector}, {address?.locality}, {address?.city}
              </p>
              <p>
                {address?.state}, {address?.pinCode}, {address?.country}
              </p>
              <p>Contact: {address?.contactNo}</p>
            </div>
          ))
        ) : (
          <p>No addresses provided</p>
        )}
        <Button
          onClick={() => navigate('/address', { state: { address: addresses?.at(0) } })}
          variant="outline"
          className="mt-4 w-full"
        >
          Edit Address
        </Button>
      </CardContent>
    </Card>
  );
};

const NameUpdateModal = ({ isOpen, setIsOpen, fetchData, userData }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  // Move useEffect before any conditional returns
  useEffect(() => {
    if (userData) {
      setName(userData?.name || '');
      setEmail(userData?.emailId || '');
    }
  }, [userData, isOpen]);

  if (!isOpen) return null;

  const handleUpdate = async () => {
    setLoading(true);
    const apiData = {
      name,
      emailId: email,
      contactNo: userData?.contactNo,
    };

    try {
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user`,
        apiData,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );
      setIsOpen(false);
      toast.success('Profile updated successfully');
      fetchData();
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-sm">
        <h2 className="text-xl font-semibold mb-4">Update Profile</h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 focus:ring-green-500"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 focus:ring-green-500"
            />
          </div>
        </div>
        <div className="flex justify-end gap-2 mt-6">
          <Button className="border-black" variant="outline" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <button
            onClick={handleUpdate}
            disabled={loading}
            className="bg-green-700 px-4 text-white rounded-md font-medium"
          >
            {loading ? 'Updating...' : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;

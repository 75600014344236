import { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export function PwaPrompt({ showPrompt, installPrompt, setInstallPrompt, setShowPrompt }) {
  const handleInstallClick = async () => {
    if (!installPrompt) return;

    installPrompt.prompt();

    const choiceResult = await installPrompt.userChoice;

    if (choiceResult.outcome === 'accepted') {
      // console.log('User accepted the install prompt');
    } else {
      handleDismiss();
    }

    setInstallPrompt(null);
    setShowPrompt(false);
  };

  const handleDismiss = () => {
    setShowPrompt(false);
    localStorage.setItem('pwaPromptDismissed', 'true');
  };

  if (!showPrompt) return null;

  return (
    <div className="fixed z-50 max-w-md w-96 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-4 border border-gray-200 animate-slide-up">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <img loading="lazy" src="/assets/pwa-192x192.png" alt="SubziWale" className="w-12 h-12" />
          <div>
            <h3 className="font-semibold text-gray-900">Install SubziWale</h3>
            <p className="text-sm text-gray-600 mt-1">
              Install our app for a better experience with quick access to fresh fruits &
              vegetables.
            </p>
          </div>
        </div>
        <button
          onClick={handleDismiss}
          className="text-gray-400 hover:text-gray-500 transition-colors"
          aria-label="Dismiss"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
      <div className="mt-4 flex space-x-3">
        <button
          onClick={handleInstallClick}
          className="flex-1 bg-green-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-green-700 transition-colors"
        >
          Install App
        </button>
        <button
          onClick={handleDismiss}
          className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
        >
          Not Now
        </button>
      </div>
    </div>
  );
}

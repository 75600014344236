import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  BadgeCheck,
  BadgeIndianRupee,
  BadgeInfo,
  Ban,
  CalendarIcon,
  FilterIcon,
  RefreshCw,
  ShoppingBag,
  ChevronRight,
  User,
} from 'lucide-react';
import BottomNav from '../layouts/Footer/BottomNav';
import axiosInstance from '../../utils/axiosInstance';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { SEO } from '../common/SEO';

function OrderHistory() {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState('All');
  const { user } = useUser();

  const fetchHistory = async () => {
    try {
      setLoading(true);

      if (!user?.id) {
        setLoading(false);
        return;
      }

      // Make API request
      const { data } = await axiosInstance.get(
        `${import.meta.env.VITE_API_URL || 'https://api.example.com'}/rest/subziwale/api/v1/orders/user?pageNo=0`,
        {
          headers: {
            'X-User-Id': user?.id,
            refreshToken: localStorage.getItem('refreshToken'),
            sessionToken: localStorage.getItem('sessionToken'),
          },
        }
      );

      setOrders(data);
      setFilteredOrders(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [user]);

  // Filter orders when statusFilter changes
  useEffect(() => {
    if (statusFilter === 'All') {
      setFilteredOrders(orders);
    } else if (statusFilter === 'Pending') {
      const filtered = orders.filter(
        (order) => order.status === statusFilter || order.status === 'Rescheduled'
      );
      setFilteredOrders(filtered);
    } else {
      const filtered = orders.filter((order) => order.status === statusFilter);
      setFilteredOrders(filtered);
    }
  }, [statusFilter, orders]);

  const handleStatusFilter = (status) => {
    setStatusFilter(status);
  };

  // Status filter options
  const statusOptions = [
    { value: 'All', icon: <FilterIcon className="w-4 h-4" />, color: 'bg-gray-100 text-gray-800' },
    {
      value: 'Pending',
      icon: <BadgeInfo className="w-4 h-4" />,
      color: 'bg-yellow-100 text-yellow-800',
    },
    {
      value: 'Paid',
      icon: <BadgeIndianRupee className="w-4 h-4" />,
      color: 'bg-green-100 text-green-800',
    },
    {
      value: 'Completed',
      icon: <BadgeCheck className="w-4 h-4" />,
      color: 'bg-blue-100 text-blue-800',
    },
    { value: 'Cancelled', icon: <Ban className="w-4 h-4" />, color: 'bg-red-100 text-red-800' },
  ];

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="bg-white px-4 py-4 flex items-center border-b sticky top-0 z-10 shadow-sm">
        <div className="flex items-center">
          <div className="w-9 h-9 rounded-full bg-green-100 flex items-center justify-center">
            <ShoppingBag className="w-5 h-5 text-green-600" />
          </div>
        </div>
        <h1 className="text-xl font-semibold flex-1 text-center">Order History</h1>
        <Link to="/profile" className="transition-transform hover:scale-105">
          <Avatar className="border-primary/10">
            <AvatarImage src="https://github.com" alt="@shadcn" />
            <AvatarFallback className="bg-blue-700/10  font-medium">
              {user?.name?.slice(0, 1) === '+' ? (
                <User className="w-8" />
              ) : (
                user?.name?.slice(0, 1) || <User className="w-8" />
              )}
            </AvatarFallback>
          </Avatar>
        </Link>
      </header>
      <SEO
        title="History - Subziwale Your Trusted Partner for Fruits & Vegetables"
        description="Browse our fresh selection of fruits, vegetables, and dairy products. High-quality produce delivered to your doorstep in Dwarka, Delhi."
        canonicalUrl="https://www.subziwale.com/history"
        ogImage="/images/leaf2.png"
      />

      {/* Main Content */}
      <main className="flex-1 px-4 pt-4 max-w-3xl pb-20 mx-auto w-full">
        {/* Filters */}
        <div className="">
          <div className="flex justify-between items-center mb-3">
            <h2 className="text-xl font-bold text-gray-800">Your Orders</h2>
            <button
              onClick={fetchHistory}
              className={`flex items-center gap-2 text-blue-600 px-3 py-1.5 rounded-full bg-blue-50 hover:bg-blue-100 transition-colors ${loading ? 'opacity-70' : ''}`}
              disabled={loading}
            >
              <RefreshCw className={`w-4 h-4 ${loading ? 'animate-spin' : ''}`} />
              <span className="text-sm font-medium">Refresh</span>
            </button>
          </div>

          <div className="flex gap-2 overflow-x-auto pb-3  px-1">
            {statusOptions.map((option) => (
              <button
                key={option.value}
                onClick={() => handleStatusFilter(option.value)}
                className={`flex items-center gap-1.5 px-3 py-1.5 rounded-sm whitespace-nowrap text-sm transition-colors ${
                  statusFilter === option.value
                    ? `${option.color} border border-current`
                    : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                }`}
              >
                {/* {option.icon} */}
                {option.value === 'Completed' ? 'Unpaid' : option.value}
              </button>
            ))}
          </div>
        </div>

        {/* Orders List */}
        {loading ? (
          <OrderSkeleton />
        ) : filteredOrders.length === 0 ? (
          <EmptyState statusFilter={statusFilter} onReset={() => setStatusFilter('All')} />
        ) : (
          <div className="space-y-2">
            {filteredOrders.map((order, idx) => (
              <OrderCard key={idx} order={order} />
            ))}
          </div>
        )}
      </main>

      {/* Bottom Navigation */}
      <BottomNav page="history" />
    </div>
  );
}

const EmptyState = ({ statusFilter, onReset }) => (
  <div className="text-center py-12 px-4 bg-white rounded-lg ">
    <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
      <ShoppingBag className="w-8 h-8 text-gray-400" />
    </div>
    <h3 className="text-lg font-semibold text-gray-800 mb-2">No orders found</h3>
    <p className="text-gray-600 mb-6">
      {statusFilter !== 'All'
        ? `You don't have any ${statusFilter.toLowerCase()} orders yet.`
        : "You haven't placed any orders yet."}
    </p>
    {statusFilter !== 'All' && (
      <button
        onClick={onReset}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
      >
        View all orders
      </button>
    )}
  </div>
);

// Order Card Component
const OrderCard = ({ order }) => {
  const navigate = useNavigate();
  const getStatusBadge = (status) => {
    switch (status) {
      case 'Completed':
        return (
          <div className="flex flex-col gap-1 items-end">
            <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              <BadgeCheck className="w-3 h-3" />
              Delivered
            </span>
          </div>
        );
      case 'Pending':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            <BadgeInfo className="w-3 h-3" />
            {status}
          </span>
        );
      case 'Cancelled':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            <Ban className="w-3 h-3" />
            {status}
          </span>
        );
      case 'Paid':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            <BadgeIndianRupee className="w-3 h-3" />
            {status}
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            <CalendarIcon className="w-3 h-3" />
            {status}
          </span>
        );
    }
  };

  const getDeliveryBadge = (order) => {
    if (order.expressDelivery) {
      return (
        <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium bg-red-50 text-red-700 border border-red-200">
          Express
        </span>
      );
    } else if (order.selfPickup) {
      return (
        <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium bg-purple-50 text-purple-700 border border-purple-200">
          Self Pickup
        </span>
      );
    } else {
      return (
        <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium bg-blue-50 text-blue-700 border border-blue-200">
          Standard
        </span>
      );
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div
      onClick={() => navigate(`/history/${order?.orderId}`)}
      className="bg-white rounded-lg overflow-hidden border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200"
    >
      {/* Order Header */}
      <div className="bg-white px-4 py-2 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="font-medium text-gray-900">{order.vendorName}</h3>
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <span className="flex items-center gap-1">
                <CalendarIcon className="w-3.5 h-3.5" />
                {formatDate(order.orderDate)}
              </span>
              <span className="text-gray-400">•</span>
              <span>{formatTime(order.orderDate)}</span>
            </div>
          </div>
          {getStatusBadge(order.status)}
        </div>
      </div>

      {/* Order Content */}
      <div className="px-4 py-2">
        {/* Products */}
        <div className="flex flex-wrap gap-2 mb-1">
          {order.products.slice(0, 4).map((product, idx) => (
            <div
              key={idx}
              className="relative w-10 h-10 rounded-md overflow-hidden bg-gray-100 border border-gray-200"
            >
              {product.productImageUrl ? (
                <img
                  loading="lazy"
                  src={product.productImageUrl}
                  alt={product.productName}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-xs bg-gray-200">
                  No image
                </div>
              )}
            </div>
          ))}
          {order.products.length > 4 && (
            <div className="w-4 h-10 rounded-md flex items-center justify-center text-sm font-medium text-gray-700">
              +{order.products.length - 4}
            </div>
          )}
        </div>

        {/* Order Footer */}
        <div className="flex justify-between items-center pt-2 border-t border-gray-100">
          <div className="text-lg font-semibold text-gray-900">₹{order.total.toFixed(2)}</div>
          <div className="flex items-center gap-2">
            {getDeliveryBadge(order)}
            <button
              onClick={() => navigate(`/history/${order?.orderId}`)}
              className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
            >
              <span className="text-sm cursor-pointer font-medium">Details</span>
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Order Skeleton Component
const OrderSkeleton = () => {
  return (
    <div className="space-y-4">
      {[1, 2, 3].map((i) => (
        <div
          key={i}
          className="bg-white rounded-lg overflow-hidden border border-gray-200 animate-pulse"
        >
          <div className="px-4 py-3 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <div>
                <div className="h-5 bg-gray-200 rounded w-32 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-40"></div>
              </div>
              <div className="h-6 bg-gray-200 rounded-full w-20"></div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex gap-2 mb-4">
              <div className="w-14 h-14 bg-gray-200 rounded-md"></div>
              <div className="w-14 h-14 bg-gray-200 rounded-md"></div>
              <div className="w-14 h-14 bg-gray-200 rounded-md"></div>
              <div className="w-14 h-14 bg-gray-200 rounded-md"></div>
            </div>
            <div className="flex justify-between items-center pt-2">
              <div className="h-6 bg-gray-200 rounded w-20"></div>
              <div className="h-6 bg-gray-200 rounded-full w-24"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderHistory;

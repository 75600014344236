import React from 'react';

export default function Loader() {
  return (
    /* From Uiverse.io by rushik0507 */
    // <svg viewBox="25 25 50 50" class="containers">
    //   <circle cx="50" cy="50" r="20" class="loader"></circle>
    // </svg>

    <div className="pulseanimate ">
      <img src="/images/leaf2.png" className="w-10" alt="loader" />
    </div>
  );
}

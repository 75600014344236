import { useEffect, useState } from 'react';
import { Truck, ShoppingBasket, DollarSign, CheckSquare, Square, ChevronLeft } from 'lucide-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import axiosInstance from '../../utils/axiosInstance';

export default function Settings() {
  const [deliveryCharges, setDeliveryCharges] = useState('');
  const [minimumOrderValue, setMinimumOrderValue] = useState('');
  const [addDeliveryCharges, setAddDeliveryCharges] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const vendorData = user;

    const apiData = {
      name: vendorData?.name,
      shopName: vendorData?.shopName,
      emailId: vendorData?.emailId,
      contactNo: vendorData?.contactNo,
      alternateContact: vendorData?.alternateContact,
      minimumOrder: minimumOrderValue,
      deliveryCharge: deliveryCharges,
      deliveryChargeWaived: addDeliveryCharges,
      categoryIds: [],
    };

    try {
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/vendor`,
        apiData,
        {
          headers: {
            'Content-Type': 'application/json',
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      navigate('/vendor/profile', { replace: true });
      toast.success('Settings Updated Successfully');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setDeliveryCharges(user?.deliveryCharge);
    setAddDeliveryCharges(user?.deliveryChargeWaived);
    setMinimumOrderValue(user?.minimumOrder);
  }, []);

  return (
    <div className="min-h-screen text-gray-800 border-x">
      <header className="bg-green-800 text-white py-3 px-3 flex items-center sticky top-0 z-10">
        <ChevronLeft
          className="mr-2 h-6 w-6 cursor-pointer mt-1"
          onClick={() => navigate('/vendor/profile', { replace: true })}
        />
        <h1 className="text-2xl font-semibold">Settings</h1>
      </header>
      <main className="px-4 py-2 max-w-md mx-auto ">
        <form onSubmit={handleSubmit} className="space-y-3">
          {/* <div className="bg-white pt-3 px-4 py-0">
            <div className="flex items-center mb-2">
              <ShoppingBasket className="w-5 h-5 text-green-500 mr-2" />
              <label className="text-base font-medium text-gray-700">Select Categories</label>
            </div>
            <div className="grid grid-cols-3 gap-2">
              {categories.map((category) => (
                <button
                  key={category}
                  type="button"
                  className={`px-2 py-2  rounded-lg flex flex-col justify-between items-center transition-all duration-300 bg-green-500 text-white`}
                  aria-pressed={selectedCategories.includes(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div> */}

          <div className="bg-white   px-4 pt-2 transition-all duration-300">
            <div className="flex items-center mb-2">
              <label htmlFor="minimum-order" className="text-base font-medium text-gray-700">
                Minimum Order Value (₹)
              </label>
            </div>
            <input
              id="minimum-order"
              type="number"
              value={minimumOrderValue}
              onChange={(e) => setMinimumOrderValue(e.target.value)}
              className="w-full px-3 py-2 border focus:border-black focus:outline-none border-gray-300 rounded-lg"
              placeholder="Enter minimum order value"
              required
              min="0"
              step="0.01"
            />
          </div>

          <div className="bg-white px-4 pt-0  transition-all duration-300">
            <label className="text-base font-medium text-gray-700 mb-2 block">
              Add delivery charges to all products?
            </label>
            <div className="flex justify-center space-x-5">
              <button
                type="button"
                onClick={() => setAddDeliveryCharges(true)}
                className={`flex-1 py-2 rounded-lg font-medium transition-all duration-300 ${
                  addDeliveryCharges
                    ? 'bg-slate-500 text-white'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
                aria-pressed={addDeliveryCharges}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => setAddDeliveryCharges(false)}
                className={`flex-1 py-2 rounded-lg font-semibold transition-all duration-300 ${
                  !addDeliveryCharges
                    ? 'bg-slate-500 text-white'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
                aria-pressed={!addDeliveryCharges}
              >
                No
              </button>
            </div>
          </div>

          <div className="bg-white rounded-md px-4 pb-4 transition-all duration-300">
            <div className="flex items-center mb-2">
              <Truck className="w-5 h-5 text-green-700 mr-2" />
              <label htmlFor="delivery-charges" className="text-base font-medium text-gray-700">
                Delivery Charges (₹)
              </label>
            </div>
            <input
              id="delivery-charges"
              type="number"
              value={deliveryCharges}
              onChange={(e) => setDeliveryCharges(e.target.value)}
              className="w-full px-3 py-2 border focus:outline-none focus:border-black border-gray-300 rounded-lg"
              placeholder="e.g, 0"
              required
              min="0"
              step="0.01"
            />
          </div>

          <div className="px-4">
            <button
              type="submit"
              className="w-full bg-green-700 text-white py-2 rounded-lg font-medium text-base hover:bg-green-600  focus:outline-none"
            >
              Update Settings
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}

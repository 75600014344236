import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { toast } from 'react-toastify';
import { SEO } from '@/components/common/SEO';

export default function OTPPage() {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(30);
  const { verifyOTP, phoneNumber, setPhoneNumber, loading, initiateLogin, autoOtp } = useAuth();
  const navigate = useNavigate();
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    if (!phoneNumber) {
      navigate('/login');
    }
  }, [phoneNumber, navigate]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer((t) => t - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleChange = (index, value) => {
    setError('');
    if (value.length > 1) value = value[0];
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp.some((digit) => !digit)) {
      setError('Please enter complete OTP');
      return;
    }

    try {
      setPhoneNumber(phoneNumber);
      const data = await verifyOTP(otp.join(''), phoneNumber);

      if (!data) {
        setError('Incorrect OTP!');
      }
    } catch (err) {
      console.error(err);
      setError('Failed to verify OTP. Please try again.');
    }
  };

  const handleResendOTP = async () => {
    try {
      setOtp(['', '', '', '']);
      await initiateLogin(phoneNumber);
      setTimer(30);
      setError('');
      toast.success('OTP resent successfully');
    } catch (err) {
      console.error(err);
      setError('Failed to resend OTP. Please try again.');
    }
  };

  useEffect(() => {
    if (autoOtp && autoOtp.length > 0) {
      setOtp(autoOtp);
      inputRefs[autoOtp.length - 1].current.focus();
    }
  }, [autoOtp]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white relative overflow-hidden">
      <SEO
        title="OTP - Subziwale"
        description="Browse our fresh selection of fruits, vegetables, and dairy products. High-quality produce delivered to your doorstep in Dwarka, Delhi."
        canonicalUrl="https://www.subziwale.com/auth/otp"
      />

      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -right-24 -top-24 w-48 h-48 rounded-full bg-green-100/50" />
        <div className="absolute -left-24 top-1/3 w-48 h-48 rounded-full bg-orange-100/50" />
        <div className="absolute right-12 bottom-1/3 w-24 h-24 rounded-full bg-yellow-100/50" />
      </div>

      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 z-50 bg-black/20 flex items-center justify-center backdrop-blur-sm">
          <div className="animate-pulse flex flex-col items-center">
            <img src="/images/leaf2.png" className="w-12 h-12 animate-bounce" alt="logo" />
            <p className="text-white font-medium mt-2">Loading...</p>
          </div>
        </div>
      )}

      <div className="min-h-screen flex flex-col px-6 py-8 relative z-10">
        <div className="flex-1 flex flex-col items-center justify-center max-w-md mx-auto w-full">
          {/* Logo */}
          <div className="w-48 mb-8 transform hover:scale-105 transition-transform duration-300">
            <img
              src="/images/logo.png"
              alt="SubziWale Logo"
              className="w-full object-contain"
              loading="eager"
            />
          </div>

          {/* Welcome Text */}
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-green-800">Enter OTP</h1>
            <p className="text-gray-600 mt-2">OTP sent to: {phoneNumber}</p>
            <p className="text-center mx-auto w-3/4 leading-7 text-lg text-green-700 font-medium mt-2">
              Local vendors, fresh produce, happy you! Let's go!
            </p>
          </div>

          {/* OTP Form */}
          <form onSubmit={handleSubmit} className="w-full space-y-6">
            <div className="flex justify-center gap-4">
              {otp.map((digit, index) => (
                <Input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={digit}
                  inputMode="numeric"
                  ref={inputRefs[index]}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  autoFocus={index === 0}
                  className="w-14 h-14 text-center text-xl font-medium bg-white/80 backdrop-blur-sm rounded-xl shadow-md border-2 border-green-100 focus:border-green-300 focus-visible:ring-offset-0 focus-visible:ring-0"
                />
              ))}
            </div>

            {error && (
              <div className="bg-red-50 text-red-600 p-3 rounded-lg text-center text-sm">
                {error}
              </div>
            )}

            <Button
              type="submit"
              disabled={loading}
              className="w-full h-12 bg-green-700 hover:bg-green-800 rounded-xl text-white font-medium text-lg shadow-lg shadow-green-100/50"
            >
              {loading ? 'Verifying...' : 'Verify OTP'}
            </Button>

            <div className="text-center">
              {timer > 0 ? (
                <p className="text-sm text-gray-600">Resend OTP in {timer} sec.</p>
              ) : (
                <Button
                  type="button"
                  variant="link"
                  onClick={handleResendOTP}
                  className="text-green-700 hover:text-green-800 hover:underline p-0 h-auto font-medium"
                >
                  Resend OTP
                </Button>
              )}
            </div>
          </form>
        </div>

        {/* Decorative Bottom Pattern */}
        <div className="absolute bottom-0 left-0 right-0 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="text-green-700/10"
          >
            <path
              fill="currentColor"
              fillOpacity="1"
              d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

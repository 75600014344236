import axios from 'axios';
import { toast } from 'react-toastify';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL, // Change this to your API base URL
  timeout: 10000,
});

// Response interceptor for handling errors globally
axiosInstance.interceptors.response.use(
  (response) => response, // Return response if successful
  (error) => {
    if (!error.response) {
      // No response from server (network error)
      if (error.code === 'ERR_CANCELED') {
        return error;
      }
      console.error('No Internet Connection');
      toast.info('No Internet Connection!');
      return Promise.reject({ message: 'No Internet Connection' });
    }

    const { status } = error.response;

    if (status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      toast.info('Session expired! Please login again');
      handleUnauthorizedError();
    }

    return Promise.reject(error);
  }
);

// Function to handle 401 Unauthorized error
const handleUnauthorizedError = () => {
  // Example: Redirect user to login page or clear token
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('user');
  localStorage.removeItem('userRole');
  localStorage.removeItem('isAuthenticated');
  window.location.href = '/login';
};

export default axiosInstance;

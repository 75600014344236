import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";

export function TermsAndConditionsPopup({ isOpen, onClose }) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-sm w-[90vw] rounded-lg h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>Terms and Conditions</DialogTitle>
        </DialogHeader>
        <ScrollArea className="flex-grow">
          <div className="space-y-4 p-4 text-sm">
            <h2 className="text-lg font-semibold">Welcome to Subziwale!</h2>
            <p>
              These Terms of Use ("Terms") govern your access and use of the
              Subziwale platform (Platform) and the services offered thereon
              (Services).
            </p>

            <h3 className="font-semibold">About Us</h3>
            <p>
              Subziwale is a Limited Liability Partnership Firm incorporated
              under the Companies Act, 2013, with its registered office at 205,
              Katyayani CGHS, Plot No-8, Sector-6, Dwarka, New Delhi-110075
              (referred to as "We," "Subziwale.com," "Subziwale," "Us," or "Our"
              in these Terms).
            </p>
            <p>
              Please note: Subziwale and the trademark "Subziwale" are not
              affiliated, linked, or connected in any way with "subjiwale.com,"
              "sabziwale.com," or "subzivale.com."
            </p>

            <h3 className="font-semibold">Understanding the Terms</h3>
            <p>
              By using our Platform or Services, you agree to be bound by these
              Terms.
            </p>

            <h3 className="font-semibold">
              Your Use of the Subziwale Platform
            </h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                You must be 18 years of age or older to use the Subziwale
                Platform.
              </li>
              <li>
                The Platform is intended for end-consumers to purchase products
                or services for personal use.
              </li>
              <li>
                You agree to use the Platform only for lawful purposes and in
                accordance with these Terms.
              </li>
            </ul>

            <h3 className="font-semibold">Account Registration</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                You may need to register and log in to place orders on the
                Subziwale Platform.
              </li>
              <li>
                You are responsible for keeping your account information
                accurate and up-to-date.
              </li>
              <li>
                By registering, you agree to receive communications from
                Subziwale, including promotional emails and newsletters. You can
                opt out of these communications at any time.
              </li>
            </ul>

            <h3 className="font-semibold">Limited License</h3>
            <p>
              Subziwale grants you a limited, non-exclusive, non-transferable
              license to access and use the Subziwale Platform for personal use
              only. You may not download (other than page caching) or modify any
              portion of the Platform without our express written consent.
            </p>

            <h3 className="font-semibold">Prohibited Conduct</h3>
            <p>
              You agree not to use the Platform for any of the following
              purposes:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Uploading or sharing illegal content</li>
              <li>Harassing or abusing others</li>
              <li>Infringing on the intellectual property rights of others</li>
              <li>Disrupting the functionality of the Platform</li>
              <li>Providing false or misleading information</li>
            </ul>

            <h3 className="font-semibold">Advertising</h3>
            <p>
              Subziwale is not responsible for the content of any advertisements
              displayed on the Platform. You should do your own research before
              responding to any advertisements.
            </p>

            <h3 className="font-semibold">Disclaimers</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Subziwale does not make any warranties or representations about
                the products or services offered on the Platform.
              </li>
              <li>
                Subziwale is not responsible for any errors or omissions on the
                Platform.
              </li>
              <li>
                You acknowledge that you are using the Platform at your own
                risk.
              </li>
            </ul>

            <h3 className="font-semibold">Delivery People</h3>
            <p>
              Delivery people are independent contractors and are not employees
              or agents of Subziwale. Subziwale is not responsible for the acts
              or omissions of delivery people.
            </p>

            <h3 className="font-semibold">Reviews, Feedback, Submissions</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Any reviews, comments, or feedback you submit to Subziwale
                become the property of Subziwale.
              </li>
              <li>
                You grant Subziwale a non-exclusive, royalty-free license to use
                your submissions for any purpose.
              </li>
              <li>
                You agree that your submissions will not violate the rights of
                any third party.
              </li>
            </ul>

            <h3 className="font-semibold">Intellectual Property</h3>
            <p>
              The content of the Subziwale Platform is protected by intellectual
              property laws. You may not copy, modify, or distribute any content
              without our express written consent.
            </p>

            <h3 className="font-semibold">Third-Party Content</h3>
            <p>
              The Subziwale Platform may contain links to third-party websites.
              Subziwale is not responsible for the content of these websites.
            </p>

            <h3 className="font-semibold">
              Report Intellectual Property Rights Infringement
            </h3>
            <p>
              If you believe that your intellectual property rights have been
              infringed on the Subziwale Platform, please contact us at
              yogesh@revvtch.com
            </p>

            <h3 className="font-semibold">Indemnification</h3>
            <p>
              You agree to indemnify Subziwale for any claims arising out of
              your use of the Platform.
            </p>

            <h3 className="font-semibold">Changes to the Terms</h3>
            <p>
              Subziwale may change these Terms at any time. We will post any
              changes on the Platform.
            </p>

            <h3 className="font-semibold">Governing Law</h3>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of India.
            </p>

            <h3 className="font-semibold">Dispute Resolution</h3>
            <p>
              Any disputes arising out of or relating to these Terms will be
              resolved by the courts of Delhi, India.
            </p>

            <h3 className="font-semibold">Entire Agreement</h3>
            <p>
              These Terms constitute the entire agreement between you and
              Subziwale regarding your use of the Platform.
            </p>
          </div>
        </ScrollArea>
        <DialogFooter className="flex flex-row items-center justify-end">
          <Button
            className="bg-green-600 focus-visible:ring-offset-0 focus-visible:ring-0 hover:bg-green-500"
            onClick={onClose}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { createContext, useEffect, useState, useContext } from "react";
import { useAuth } from "./AuthContext";

const VendorContext = createContext({});

export function VendorProvider({ children }) {
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);

  const updateSelectedVendor = (vendor) => {
    setSelectedVendor(vendor);

    localStorage.setItem("selectedVendor", JSON.stringify(vendor));
  };

  return (
    <VendorContext.Provider
      value={{
        setSelectedVendor: updateSelectedVendor,
        selectedVendor,
        setVendors,
        vendors,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
}

export const useVendor = () => useContext(VendorContext);

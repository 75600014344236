import axios from "axios";
import jwtEncode from "jwt-encode";
import { toast } from "react-toastify";

export const indianStates = [
  "Delhi",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",

  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];

export const generateToken = (user) => {
  const secret = "your-secret-key";
  const expirationTime = Math.floor(Date.now() / 1000) + 86400;

  const payload = {
    ...user,
    exp: expirationTime,
  };

  return jwtEncode(payload, secret);
};

export const fetchSocieties = async (query) => {
  try {
    const sessionToken = localStorage.getItem("sessionToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const { data } = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/rest/subziwale/api/v1/address/directory/society?societyName=${query}`,
      {
        headers: {
          sessionToken,
          refreshToken,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    if (
      error.status == 401 ||
      error?.response?.data == "Unauthorized: Invalid or missing token."
    ) {
      toast.error("Session expired! Please login again");
      localStorage.removeItem("user");
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      return [];
    }
    return ["Not Found"];
  }
};

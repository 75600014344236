import { createContext, useEffect, useState } from 'react';
import fetchUser from '../utils/fetchUser';
import { useContext } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext({});

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const refreshUser = async (phone) => {
    try {
      setUserLoading(true);
      const userData = await fetchUser(phone);
      setUser(userData);
    } catch (error) {
      console.error(error);
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('sessionToken')) {
      localStorage.clear();
      navigate('/login');
      return;
    }
    const contactNo = localStorage.getItem('contactNo');
    if (!contactNo) {
      logout();
    }
    refreshUser(contactNo);
  }, []);

  const value = {
    setUser,
    user,
    userLoading,
    setUserLoading,
    refreshUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";

export function PrivacyPolicyPopup({ isOpen, onClose }) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-sm w-[90vw] rounded-lg h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>Privacy Policy</DialogTitle>
        </DialogHeader>
        <ScrollArea className="flex-grow">
          <div className="space-y-4 p-4 text-sm">
            <h2 className="text-lg font-semibold">Your Privacy at Subziwale</h2>
            <p>
              Subziwale respects your privacy and is committed to protecting
              your personal information. This Privacy Policy explains how we
              collect, use, disclose, and protect your information when you use
              our platform and services.
            </p>

            <h3 className="font-semibold">Information We Collect</h3>
            <p>
              We collect information you provide directly, such as your name,
              address, phone number, email address, and payment information when
              you create an account, place an order, or contact us.
            </p>
            <p>
              We also collect information automatically when you use our
              platform, such as your IP address, device type, browsing activity,
              and purchase history.
            </p>

            <h3 className="font-semibold">How We Use Your Information</h3>
            <p>
              We use your information to provide you with the services you
              request, process your orders, deliver products, communicate with
              you about your orders, and personalize your experience on our
              platform.
            </p>
            <p>
              We may also use your information for marketing purposes, such as
              sending you promotional emails or notifications. You can opt out
              of marketing communications at any time.
            </p>

            <h3 className="font-semibold">Information Sharing</h3>
            <p>
              We may share your information with third-party service providers
              who help us operate our platform and deliver our services. These
              service providers are contractually obligated to protect your
              information.
            </p>
            <p>
              We may also disclose your information if required by law or to
              protect our rights or the safety of others.
            </p>

            <h3 className="font-semibold">Data Security</h3>
            <p>
              We take reasonable steps to protect your information from
              unauthorized access, disclosure, alteration, or destruction.
              However, no internet transmission is completely secure.
            </p>

            <h3 className="font-semibold">Call Recording</h3>
            <p>
              We may record calls between you and our customer service
              representatives for training and quality purposes. You will be
              notified if a call is being recorded.
            </p>

            <h3 className="font-semibold">Your Choices</h3>
            <p>
              You can access and update your personal information in your
              account settings. You can also opt out of marketing communications
              at any time.
            </p>

            <h3 className="font-semibold">Changes to this Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. We will post
              any changes on our platform.
            </p>

            <h3 className="font-semibold">Contact Us</h3>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at yogesh@revvtch.com
            </p>

            <h3 className="font-semibold">Taxes on Your Order</h3>
            <p>
              Subziwale will issue relevant tax documents, such as invoices, as
              required by law. These documents will be available on your order
              summary page after delivery.
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Goods: For goods supplied by third-party sellers listed on the
                Subziwale Platform, you will receive a Tax Invoice cum Bill of
                Supply issued on behalf of the seller, as applicable.
              </li>
              <li>
                Services: For services provided by Subziwale or third-party
                service providers, you will receive a Tax Invoice.
              </li>
            </ul>
            <p>
              To avail GST benefits, you must provide a valid GST number during
              the order placement process. Your eligibility for GST benefits is
              subject to the GST terms and conditions, which are incorporated
              into these Terms.
            </p>

            <h3 className="font-semibold">Order Cancellation</h3>
            <p>
              You may request to cancel an order, but cancellation is subject to
              Subziwale's approval.
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Order Cancellations by You: Cancellation requests may be
                approved by Subziwale. If approved, a refund, if applicable,
                will be issued in the form of promotional codes redeemable on
                future orders.
              </li>
              <li>
                Order Cancellations by Subziwale: Subziwale may cancel orders
                due to fraud, violations of these Terms, product unavailability,
                or unforeseen circumstances. Refunds, if applicable, will be
                processed within 72 hours.
              </li>
              <li>
                Order Cancellations Due to Errors: If an error in product
                information or pricing is discovered, Subziwale reserves the
                right to cancel the order and issue a refund in the form of
                promotional codes.
              </li>
            </ul>

            <h3 className="font-semibold">Returns & Refunds</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                General: Products are generally
                non-returnable/non-replaceable/non-exchangeable. Exceptions may
                apply for damaged, defective, expired, or incorrectly delivered
                products, or as specified in the product's return policy.
              </li>
              <li>
                Refund Processing: Refunds for eligible returns and
                cancellations will be processed within 7 business days.
              </li>
              <li>
                Refund Methods: Refunds for online payments may be issued as
                credits, cashbacks, or promotional codes. Refunds for cash on
                delivery orders will be issued as promotional codes.
              </li>
              <li>Currency: All refunds will be issued in Indian Rupees.</li>
            </ul>

            <h3 className="font-semibold">Promotional Terms</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Eligibility: Offers are subject to specific eligibility criteria
                and may have limitations.
              </li>
              <li>
                Duration: Offers may be modified or discontinued at any time.
              </li>
              <li>
                Returns and Refunds: Offers may be void if the order is
                returned, refunded, or cancelled.
              </li>
              <li>
                Third-Party Involvement: Subziwale is not responsible for
                services provided by third parties in connection with offers.
              </li>
              <li>
                Data Sharing: By participating in an offer, you consent to the
                sharing of your information with third parties.
              </li>
              <li>
                Disclaimer: Subziwale reserves the right to modify or cancel
                offers at any time.
              </li>
            </ul>

            <h3 className="font-semibold">Payments</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Payment Methods: Payments for orders must be made in Indian
                Rupees. Accepted payment methods include credit card, debit
                card, net banking, UPI, cash on delivery, and other methods as
                may be available on the Platform.
              </li>
              <li>
                Third-Party Payment Processors: Subziwale may use third-party
                payment processors to facilitate payments. You agree to be bound
                by the terms and conditions of such third-party processors.
              </li>
              <li>
                Payment Responsibility: You are solely responsible for ensuring
                the accuracy and validity of your payment information.
              </li>
              <li>
                Payment Fees: You may be charged additional fees by your bank or
                payment issuer for certain payment methods.
              </li>
            </ul>

            <h3 className="font-semibold">Order History</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Deleting Orders: Deleting an order from your account history
                will make it inaccessible to you. However, it may still be
                accessible to others who have received order information from
                you.
              </li>
              <li>
                Irreversibility: Deleted orders cannot be retrieved or used for
                support requests.
              </li>
              <li>
                Data Retention: Deleted orders may still be retained by
                Subziwale for record-keeping purposes.
              </li>
            </ul>

            <h3 className="font-semibold">Grievance Redressal</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Initial Contact: For order-related issues, please contact us
                through the in-app chat support for the quickest resolution.
              </li>
              <li>
                Grievance Officer: For formal complaints, please contact our
                Grievance Officer as per the details published on the Platform.
              </li>
            </ul>

            <h3 className="font-semibold">Indemnification</h3>
            <p>
              You agree to indemnify Subziwale against any claims arising from
              your use of the Platform, including violations of these Terms or
              infringement of third-party rights.
            </p>

            <h3 className="font-semibold">Limitation of Liability</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Subziwale's liability is limited to the refund of the order
                amount in case of court-ordered liability.
              </li>
              <li>
                Subziwale is not liable for any indirect, special, or
                consequential damages.
              </li>
              <li>
                You must file any legal action within one year of the alleged
                harm.
              </li>
            </ul>

            <h3 className="font-semibold">Termination</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Subziwale may terminate your account or restrict your access to
                the Platform at any time for any reason, including violations of
                these Terms.
              </li>
              <li>You may delete your account at any time.</li>
              <li>
                Upon termination, your obligation to pay for existing orders
                remains.
              </li>
            </ul>

            <h3 className="font-semibold">Governing Law & Jurisdiction</h3>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of India. Courts in New Delhi shall have exclusive
              jurisdiction over any disputes arising from these Terms.
            </p>

            <h3 className="font-semibold">Miscellaneous</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Severability: If any provision of these Terms is found to be
                invalid, the remaining provisions shall remain in full force and
                effect.
              </li>
              <li>
                Entire Agreement: These Terms, together with the Privacy Policy,
                constitute the entire agreement between you and Subziwale.
              </li>
            </ul>
          </div>
        </ScrollArea>
        <DialogFooter className="flex flex-row gap-4 items-center justify-end">
          <Button
            className="bg-green-600 focus-visible:ring-offset-0 focus-visible:ring-0 hover:bg-green-500"
            onClick={onClose}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/UserContext';
import axiosInstance from '../../utils/axiosInstance';

const AuthDetails = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const { user } = useUser();
  const [address, setAddress] = useState(user?.addressModel?.[0] || {});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !address) {
      navigate('/');
      return;
    }

    try {
      const apiData = {
        name,
        emailId: '',
        contactNo: user?.contactNo,
        userType: 'user',
      };

      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user`,
        apiData,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      await axiosInstance.post(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/address`,
        { ...address, defaultAdd: true },
        {
          headers: {
            'X-User-Id': user?.id,
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSkip = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-white">
      <header className="bg-green-700 text-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <img loading="lazy" src="/images/leafsubzi.png" alt="leafsubzi" className="w-28" />
        </div>
      </header>
      <div className="max-w-md mx-auto py-4 px-6  mt-10">
        <h2 className="text-2xl font-semibold text-center mb-4">Complete Your Profile</h2>
        <p className="text-sm text-gray-600 text-center mb-4">
          You can fill out the details now or skip for later.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Your Name</label>
            <input
              type="text"
              className="w-full border focus:border-green-600 rounded-md focus:outline-none p-2 mt-1"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">House/Flat No</label>
            <input
              type="text"
              className="w-full border focus:outline-none focus:border-green-600 rounded-md p-2 mt-1"
              value={address?.unitNo}
              onChange={(e) =>
                setAddress((prev) => ({
                  ...prev,
                  unitNo: e.target.value,
                }))
              }
              placeholder="Enter your address"
            />
          </div>
          <div className="flex justify-end items-center gap-3 mt-4">
            <button
              type="button"
              className="px-6 py-2 bg-gray-500 text-white rounded-md"
              onClick={handleSkip}
            >
              Later
            </button>
            <button type="submit" className="px-6 py-2 bg-blue-600 text-white rounded-md">
              Finish
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthDetails;

'use client';

import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Search, ChevronLeft, Home, X, ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Badge } from '@/components/ui/badge';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/UserContext';

const VendorServiceAreas = () => {
  const { user, refreshUser } = useUser();
  const [areas, setAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sectorFilter, setSectorFilter] = useState('');
  const [plotNoFilter, setPlotNoFilter] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [loading, setLoading] = useState(false);
  const [isSelectedAreasOpen, setIsSelectedAreasOpen] = useState(true);
  const navigate = useNavigate();

  const fetchAreas = async (selectedData) => {
    try {
      const { data } = await axios.get(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/address/directory/state?state=${
          user?.address?.state
        }`,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );
      const areasWithSelection = data.map((area) => ({
        ...area,
        isSelected: selectedData?.some((selectedArea) => selectedArea.id === area.id),
      }));

      // Update areas state and initialize selected areas
      setAreas(areasWithSelection);
      const initialSelectedAreas = areasWithSelection.filter((area) => area.isSelected);
      setSelectedAreas(initialSelectedAreas);
    } catch (error) {
      console.error('Error fetching areas:', error);
      toast.error('Failed to load areas. Please try again.');
    }
  };

  const handleSelectArea = (id) => {
    setIsSelectedAreasOpen(false);

    // Update areas selection state
    setAreas((prevAreas) =>
      prevAreas.map((area) => (area.id === id ? { ...area, isSelected: !area.isSelected } : area))
    );

    // Update selected areas
    setSelectedAreas((prev) => {
      const area = areas.find((a) => a.id === id);
      if (!area) return prev;

      const isCurrentlySelected = prev.some((a) => a.id === id);
      if (isCurrentlySelected) {
        return prev.filter((a) => a.id !== id);
      } else {
        return [...prev, area];
      }
    });
  };

  const handleRemoveArea = (id) => {
    // Update both areas and selectedAreas states
    setAreas((prevAreas) =>
      prevAreas.map((area) => (area.id === id ? { ...area, isSelected: false } : area))
    );
    setSelectedAreas((prev) => prev.filter((area) => area.id !== id));
  };

  const filteredAreas = areas
    .filter((area) => {
      const matchesSearch =
        area.societyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area.plotNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area.pinCode.includes(searchTerm);

      const matchesSector =
        !sectorFilter || area.sector.toLowerCase().includes(sectorFilter.toLowerCase());

      const matchesPlotNo =
        !plotNoFilter || area.plotNo.toLowerCase().includes(plotNoFilter.toLowerCase());

      return matchesSearch && matchesSector && matchesPlotNo;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.societyName.localeCompare(b.societyName);
        case 'sector':
          return a.sector.localeCompare(b.sector);
        case 'pincode':
          return a.pinCode.localeCompare(b.pinCode);
        default:
          return 0;
      }
    });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const apiData = selectedAreas.map((area) => area.id);

      const response = await axios.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/vendor/service-area?vendorId=${user?.id}`,
        apiData,
        {
          headers: {
            'Content-Type': 'application/json',
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      // Update local storage with new service areas

      refreshUser(user?.contactNo);

      toast.success('Your service areas have been updated successfully!');
      navigate(-1, { replace: true });
    } catch (error) {
      console.error('Error saving service areas:', error);
      toast.error('Error while saving service areas');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setSelectedAreas(user?.serviceArea);
      fetchAreas(user?.serviceArea);
    } else {
      const contactNo = localStorage.getItem('contactNo');
      refreshUser(contactNo);
    }
    window.scrollTo(0, 0);
  }, [user]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="sticky top-0 z-10 bg-green-800 text-white p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <ChevronLeft onClick={() => navigate(-1)} className="h-6 w-6 cursor-pointer mr-2" />
            <h1 className="text-xl font-bold">Select Delivery Areas</h1>
          </div>
          <Home onClick={() => navigate('/')} className="h-6 w-6 cursor-pointer" />
        </div>
      </header>

      <main className="flex-grow py-2 px-4">
        <Collapsible
          open={isSelectedAreasOpen}
          onOpenChange={setIsSelectedAreasOpen}
          className="mb-2 space-y-1"
        >
          <CollapsibleTrigger asChild>
            <Button variant="outline" className="w-full flex justify-between items-center">
              <span>Selected Areas ({selectedAreas.length})</span>
              {isSelectedAreasOpen ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="flex flex-wrap gap-1">
              {selectedAreas.map((area) => (
                <Badge key={area.id} variant="secondary" className="px-2 py-1 bg-gray-50">
                  <span className="truncate max-w-32">
                    {area.societyName ? (
                      area.societyName
                    ) : (
                      <>
                        Plot No {area.plotNo}, Sector {area.sector}
                      </>
                    )}
                  </span>
                  <X
                    className="ml-1 h-3 w-3 cursor-pointer"
                    onClick={() => handleRemoveArea(area.id)}
                  />
                </Badge>
              ))}
            </div>
          </CollapsibleContent>
        </Collapsible>

        <div className="mb-3 space-y-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              type="text"
              placeholder="Search for an area"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 w-full rounded-lg focus:outline-none focus-visible:ring-offset-0 focus-visible:ring-1 focus-visible:ring-green-700"
            />
          </div>
          <div className="flex space-x-2">
            <Input
              type="text"
              placeholder="Filter by sector"
              value={sectorFilter}
              onChange={(e) => setSectorFilter(e.target.value)}
              className="flex-1 w-1/3 rounded-lg focus:outline-none focus-visible:ring-offset-0 focus-visible:ring-1 focus-visible:ring-green-700"
            />
            <Input
              type="text"
              placeholder="Filter by Plot No"
              value={plotNoFilter}
              onChange={(e) => setPlotNoFilter(e.target.value)}
              className="flex-1 rounded-lg focus:outline-none focus-visible:ring-offset-0 focus-visible:ring-1 focus-visible:ring-green-700"
            />
            <Select
              value={sortBy}
              onValueChange={setSortBy}
              className="focus-visible:ring-offset-0 focus-visible:ring-1 focus-visible:ring-green-700"
            >
              <SelectTrigger className="w-[120px] text-left focus:ring-1 focus:ring-offset-0 focus-visible:ring-offset-0 focus-visible:ring-0 focus:ring-green-700">
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="name">Sort by Name</SelectItem>
                <SelectItem value="sector">Sort by Sector</SelectItem>
                <SelectItem value="pincode">Sort by Pincode</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <ScrollArea className="h-[calc(100vh-310px)]">
          <div className="space-y-2">
            {filteredAreas.map((area) => (
              <div
                key={area.id}
                className={`p-3 bg-white cursor-pointer rounded-lg shadow flex items-center justify-between ${
                  area.isSelected ? 'border-2 border-green-500' : ''
                }`}
                onClick={() => handleSelectArea(area.id)}
              >
                <div>
                  <h3 className="font-semibold">
                    {area.societyName},{' '}
                    <span className="font-normal text-sm">
                      Plot No {area.plotNo}, Sector {area.sector}
                    </span>
                  </h3>
                  <p className="text-sm text-gray-600">
                    {area.locality}, {area.state}, {area.district}, {area.country}, {area.pinCode}
                  </p>
                </div>
                <input
                  type="checkbox"
                  checked={area.isSelected}
                  onChange={() => {}}
                  className="h-5 w-5 text-green-600"
                />
              </div>
            ))}
          </div>
        </ScrollArea>
      </main>

      <footer className="sticky bottom-0 bg-white border-t p-4">
        <Button
          onClick={handleSubmit}
          disabled={loading || selectedAreas.length === 0}
          className="w-full py-2 bg-green-700 text-white rounded-lg font-semibold hover:bg-green-700 transition-colors"
        >
          {loading ? 'Updating...' : 'Update Selected Areas'}
        </Button>
      </footer>
    </div>
  );
};

export default VendorServiceAreas;

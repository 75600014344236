import React, { useState, useEffect } from 'react';
import { ArrowLeft, Check, X, Minus, Plus, Trash2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import axios from 'axios';
import Loader from '@/components/common/Loader';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import { toast } from 'react-toastify';
import { useAuth } from '@/contexts/AuthContext';
import { useSwipeable } from 'react-swipeable';
import { useUser } from '../../contexts/UserContext';
import axiosInstance from '../../utils/axiosInstance';

const API_URL = import.meta.env.VITE_API_URL;

export default function ProductPricing() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Vegetables');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [changedItems, setChangedItems] = useState(new Set());
  const [navigation, setNavigation] = useState('');
  const [newCategory, setNewCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { user } = useUser();

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      const currCategory = selectedCategory;
      const idx = categories.findIndex((i) => i === currCategory);

      if (eventData?.dir === 'Right' && idx > 0) {
        if (changedItems.size > 0) {
          setNewCategory(categories.at(idx + 1));
          setShowConfirmDialog(true);
        } else {
          setSelectedCategory(categories.at(idx - 1));
        }
      } else if (eventData?.dir === 'Left' && idx < categories?.length - 1) {
        if (changedItems.size > 0) {
          setNewCategory(categories.at(idx + 1));
          setShowConfirmDialog(true);
        } else {
          setSelectedCategory(categories.at(idx + 1));
        }
      }
    },
  });

  const handleNavigation = (to) => {
    if (changedItems.size > 0) {
      setShowConfirmDialog(true);
      setNavigation(to);
    } else {
      navigateTo(to);
    }
  };

  const navigateTo = (to) => {
    if (typeof to === 'number') {
      navigate(to);
    } else {
      navigate(to);
    }
  };

  const fetchProducts = async (controller) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `${API_URL}/rest/subziwale/api/v1/products?category=${selectedCategory}`,
        {
          headers: {
            'X-VENDOR-ID': user?.id,
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
          signal: controller.signal,
        }
      );
      setProducts(data);
      setFilteredProducts(data);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const updatePrice = (id, newValue) => {
    const updatedProducts = products.map((product) => {
      if (product.productId === id) {
        const updatedProduct = {
          ...product,
          netPrice: newValue,
        };
        if (updatedProduct.netPrice !== product.netPrice) {
          setChangedItems((prev) => new Set(prev).add(id));
        } else {
          setChangedItems((prev) => {
            const newSet = new Set(prev);
            newSet.delete(id);
            return newSet;
          });
        }
        return updatedProduct;
      }
      return product;
    });
    setProducts(updatedProducts);
    filterProducts(updatedProducts, searchQuery);
  };

  const toggleAvailability = (id) => {
    const updatedProducts = products.map((product) => {
      if (product.productId === id) {
        const updatedProduct = {
          ...product,
          available: !product.available,
        };
        setChangedItems((prev) => new Set(prev).add(id));
        return updatedProduct;
      }
      return product;
    });
    setProducts(updatedProducts);
    filterProducts(updatedProducts, searchQuery);
  };

  const saveChanges = async () => {
    const changedProducts = products
      .filter((product) => changedItems.has(product.productId))
      .map((product) => ({
        productId: product.productId,
        netPrice: product.netPrice,
        available: product.available,
      }));
    const vendorId = user?.id;
    try {
      const sessionToken = localStorage.getItem('sessionToken');
      const refreshToken = localStorage.getItem('refreshToken');
      await axios.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/products/vendor`,
        changedProducts,
        {
          headers: {
            'X-Vendor-Id': vendorId,
            sessionToken,
            refreshToken,
          },
        }
      );
      setChangedItems(new Set());
    } catch (error) {
      console.error('Error saving changes', error);
      toast.error('Error changing price');
      if (
        error.status == 401 ||
        error?.response?.data == 'Unauthorized: Invalid or missing token.'
      ) {
        toast.error('Session expired! Please login again');
        logout();
      }
    }
  };

  const filterProducts = (productsToFilter, query) => {
    const filtered = productsToFilter.filter((product) =>
      product.productName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    const controller = new AbortController();
    if (user) {
      fetchProducts(controller);
    }
    return () => controller.abort();
  }, [selectedCategory, user]);

  useEffect(() => {
    filterProducts(products, searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (user) {
      setCategories(user?.vendorCategories);
      setSelectedCategory(user?.vendorCategories[0]);
    }
  }, [user]);

  return (
    <div className="flex sm:border-l sm:border-r h-screen flex-col bg-white">
      {/* Header */}
      <header className="flex h-16 items-center gap-4 bg-white border-b px-4 sticky top-0 z-10">
        <button className="p-1" aria-label="Go back" onClick={() => handleNavigation(-1)}>
          <ArrowLeft className="h-5 w-5 text-gray-600" />
        </button>
        <h1 className="text-lg font-semibold">Pricing & Availability</h1>
        <VendorMenu user={user} />
      </header>

      {/* Category Selection */}
      <div className="px-2 py-1 sticky top-14 bg-white z-10 shadow-sm">
        <Tabs
          defaultValue="Vegetables"
          onValueChange={(category) => {
            if (changedItems.size > 0) {
              setNewCategory(category);
              setShowConfirmDialog(true);
            } else {
              setSelectedCategory(category);
            }
          }}
          className="w-full"
        >
          <TabsList className="w-full bg-gray-100 px-0 py-0 rounded-full gap-2 shadow-none">
            {categories?.map((tab, idx) => {
              return (
                <TabsTrigger
                  key={idx}
                  value={tab}
                  onClick={() => {
                    if (changedItems.size > 0) {
                      setNewCategory(category);
                      setShowConfirmDialog(true);
                    } else {
                      setSelectedCategory(tab);
                    }
                  }}
                  data-state={tab === selectedCategory ? 'active' : 'inactive'}
                  className="flex-1 h-full data-[state=active]:bg-[#39c55e] data-[state=active]:text-white rounded-full transition-all duration-200"
                >
                  <img
                    loading="lazy"
                    src={`/${tab}.png`}
                    alt=""
                    className="w-5 h-5 mr-2 inline-block"
                  />
                  <span>{tab}</span>
                </TabsTrigger>
              );
            })}
          </TabsList>
        </Tabs>
      </div>

      {/* Search Bar */}
      <div className="px-4 py-1 sticky top-[88px] bg-white z-10">
        <div className="relative">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 pl-10 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <img
            loading="lazy"
            src="/images/leaf.png"
            alt="leaf"
            className="w-6 absolute left-3 top-1/2 transform -translate-y-1/2"
          />
        </div>
      </div>

      {/* Product List */}
      <div className="flex-1 overflow-auto" {...handlers}>
        {/* Field Headers */}
        <div className="sticky top-0 bg-white border-b px-4 py-3 flex items-center justify-between text-sm font-medium text-gray-600">
          <div className="w-1/3">Product</div>
          <div className="flex items-center justify-end gap-1 w-2/3">
            <div className="w-20 text-center ml-1">Price</div>
            <div className="w-12 text-center">Unit</div>
            <div className="w-8 text-center">Stock</div>
          </div>
        </div>

        <ul className="px-2 pt-0 pb-32 gap-3">
          {loading ? (
            <div className="fixed top-0 max-w-md h-full  w-full flex items-center justify-center">
              <Loader />
            </div>
          ) : filteredProducts?.length === 0 ? (
            <div className="text-center py-4 text-gray-500">
              No products found matching your search.
            </div>
          ) : (
            filteredProducts?.map((product) => (
              <li
                key={product.productId}
                className={`flex items-center my-2 w-full pr-2 rounded-md border-b ${
                  changedItems.has(product.productId) ? 'bg-yellow-50' : 'bg-white'
                } border-gray-200`}
              >
                <img
                  loading="lazy"
                  src={product.productImageUrl}
                  alt={product.productName}
                  className="h-14 w-14 rounded-md object-cover mr-3"
                />
                <div className="flex flex-1 items-center justify-between">
                  <div className="w-1/3">
                    <h2 className="text-sm flex flex-col items-start">
                      {product.productName}
                      {product.localName && (
                        <span className="text-gray-500">({product.localName})</span>
                      )}
                    </h2>
                  </div>
                  <div className="flex items-center gap-1 justify-end w-2/3">
                    <div className="flex items-center">
                      <button
                        onClick={() => updatePrice(product.productId, product.netPrice - 5)}
                        className="px-1"
                      >
                        <Minus className="h-4 w-4 text-gray-500" />
                      </button>
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="Price"
                        value={product.netPrice === '' ? '' : product.netPrice}
                        onChange={(e) => updatePrice(product.productId, Number(e.target.value))}
                        className="h-6 w-14 text-sm border-b border-gray-300 focus:outline-none text-center"
                      />
                      <button
                        onClick={() => updatePrice(product.productId, product.netPrice + 5)}
                        className="px-1"
                      >
                        <Plus className="h-4 w-4 text-gray-500" />
                      </button>
                    </div>
                    <span className="text-sm text-gray-500 w-12 text-center">
                      {product.baseUnit}
                    </span>
                    <button
                      onClick={() => toggleAvailability(product.productId)}
                      className="w-8 flex justify-center"
                    >
                      {product.available ? (
                        <Check className="h-5 w-5 text-green-500" />
                      ) : (
                        <X className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </div>
              </li>
            ))
          )}
        </ul>
        {changedItems.size > 0 && (
          <div className="fixed bottom-16 w-full max-w-md items-center justify-center text-sm left-1/2 transform -translate-x-1/2 flex gap-2">
            <button
              onClick={() => {
                fetchProducts({ signal: new AbortController().signal });
                setChangedItems(new Set());
              }}
              className="px-3 py-2  flex items-center gap-1 bg-red-600 text-white rounded-md shadow-lg"
            >
              Discard <Trash2 className="h-4 w-4" />
            </button>
            <button
              onClick={saveChanges}
              className="px-4 py-2 bg-green-500 text-white rounded-md shadow-lg"
            >
              Save Changes
            </button>
          </div>
        )}
      </div>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Unsaved Changes</DialogTitle>
          </DialogHeader>
          <p>
            You have unsaved changes. Do you want to save before{' '}
            {newCategory && typeof newCategory === 'string' ? 'changing categories' : 'leaving'}?
          </p>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setShowConfirmDialog(false);
                fetchProducts({ signal: new AbortController().signal });
                setChangedItems(new Set());
                if (navigation) {
                  navigate(navigation);
                } else {
                  if (newCategory) {
                    if (typeof newCategory === 'string') {
                      setSelectedCategory(newCategory);
                    } else {
                      navigateTo(newCategory);
                    }
                  }
                  setNewCategory(null);
                }
              }}
            >
              Discard changes
            </Button>
            <Button
              onClick={() => {
                saveChanges();
                setShowConfirmDialog(false);
                if (navigation) {
                  navigate(navigation);
                } else {
                  if (newCategory) {
                    if (typeof newCategory === 'string') {
                      setSelectedCategory(newCategory);
                    } else {
                      navigateTo(newCategory);
                    }
                  }
                  setNewCategory(null);
                }
              }}
              className="bg-green-600 hover:bg-green-600/90"
            >
              Save and continue
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <BottomNav page="pricing" />
    </div>
  );
}

const VendorMenu = ({ user }) => {
  return (
    <Link to="/vendor/profile" className="ml-auto mr-2">
      <Avatar className="border-primary/10">
        <AvatarImage src="https://github.com" alt="@shadcn" />
        <AvatarFallback className="bg-blue-700/10  font-medium">
          {user?.name?.slice(0, 1)}
        </AvatarFallback>
      </Avatar>
    </Link>
  );
};

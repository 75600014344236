'use client';

import { useEffect, useState } from 'react';
import { Check, ChevronsUpDown, Heart } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useVendor } from '@/contexts/VendorContext';
import { useAuth } from '@/contexts/AuthContext';
import { useCart } from '@/hooks/useCart';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { useUser } from '../../contexts/UserContext';

export function VendorSelect({ refresh = false }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const { logout } = useAuth();
  const { user, refreshUser } = useUser();
  const { clearCart } = useCart();
  const { selectedVendor, setVendors, vendors, setSelectedVendor } = useVendor();
  const navigate = useNavigate();

  const selectVendor = async (vendor, direct = false) => {
    const selectedData = {
      vendorName: vendor?.name,
      shopName: vendor?.shopName,
      vendorId: vendor?.id,
      societyName: vendor?.address?.societyName,
      contactNo: vendor?.contactNo,
      status: vendor?.status,
      deliveryCharge: vendor?.deliveryCharge,
      deliveryChargeWaived: vendor?.deliveryChargeWaived,
      minimumOrder: vendor?.minimumOrder,
      vendorCategories: vendor?.vendorCategories,
    };

    const apiData = [
      {
        vendorId: selectedData?.vendorId,
        societyName: selectedData?.societyName,
        vendorName: selectedData?.vendorName,
        favourite: true,
        contactNo: selectedData?.contactNo,
      },
    ];

    try {
      await axiosInstance.put(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/vendors?userId=${user?.id}`,
        apiData,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      if (direct) {
        navigate('/dashboard');
      }
      setSelectedVendor(selectedData);
      clearCart();
      setValue(vendor.name);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const fetchNearbyVendors = async () => {
    try {
      const address = user?.addressModel[0];

      const response = await axiosInstance.get(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/near-by-vendors?plotNo=${
          address?.plotNo
        }&sector=${address?.sector}&state=${address?.state}`,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      setVendors(response?.data);

      const res = await axiosInstance.get(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user/vendors?userId=${user?.id}`,
        {
          headers: {
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );

      if (!res?.data || res?.data?.length === 0) {
        selectVendor(response?.data[0]);
        return;
      }

      let selectedData;

      const vendorData = response?.data?.find((item) => {
        return item?.id === res?.data[0].vendorId;
      });

      if (vendorData) {
        selectedData = {
          vendorName: vendorData?.name,
          shopName: vendorData?.shopName,
          vendorId: vendorData?.id,
          societyName: vendorData?.address?.societyName,
          contactNo: vendorData?.contactNo,
          status: vendorData?.status,
          deliveryCharge: vendorData?.deliveryCharge,
          deliveryChargeWaived: vendorData?.deliveryChargeWaived,
          minimumOrder: vendorData?.minimumOrder,
          vendorCategories: vendorData?.vendorCategories,
        };
      } else {
        selectVendor(response?.data[0]);
        return;
      }
      setSelectedVendor(selectedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const isVendorSelected = JSON.parse(localStorage.getItem('selectedVendor'));

    if (vendors.find((vendor) => vendor.name === isVendorSelected?.vendorName)?.name == undefined) {
      localStorage.removeItem('selectedVendor');
      // fetchNearbyVendors();
    }

    if (isVendorSelected) {
      setValue(isVendorSelected?.vendorName);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (user) {
      fetchNearbyVendors();
    } else {
      const contactNo = localStorage.getItem('contactNo');
      if (!contactNo) {
        logout();
      }
      refreshUser(contactNo);
    }
  }, [user]);

  useEffect(() => {
    if (refresh) {
      fetchNearbyVendors();
    }
  }, [refresh]);

  return (
    <Popover open={open} onOpenChange={setOpen} className="w-full">
      <PopoverTrigger asChild className="w-full text-left">
        <Button variant="outline" role="combobox" aria-expanded={open} className="justify-between">
          {value ? (
            <span className="flex items-center gap-2 sm:text-lg text-base">
              <Heart className={cn('h-12 w-12 mr-2 fill-red-500  text-red-500')} />
              {vendors.find((vendor) => vendor.name === value)?.name}
              <span className="sm:text-base font-normal text-gray-800 sm:block hidden">
                {vendors.find((vendor) => vendor.name === value)?.contactNo}
              </span>
            </span>
          ) : (
            'Select vendor...'
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-full">
        <Command className="w-[calc(83vw)] sm:w-[calc(90vw)] max-w-sm">
          <CommandList>
            <CommandEmpty>No vendor found.</CommandEmpty>
            <CommandGroup className="p-0">
              {vendors.map((vendor, idx) => (
                <CommandItem
                  key={vendor.name + '' + idx}
                  onSelect={() => {
                    selectVendor(vendor, true);
                  }}
                  className="cursor-pointer rounded-none border-b border-gray-400 last:border-none gap-0 h-full data-[selected=true]:bg-white p-0"
                >
                  <div
                    className={`w-12   flex items-center justify-center ${
                      value === vendor.name ? '' : ''
                    }`}
                    title={value === vendor.name ? 'Favourite vendor' : ''}
                  >
                    <Heart
                      className={cn(
                        'h-12 w-12 fill-red-500  text-red-500',
                        value === vendor.name ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                  </div>
                  <div className={`pl-2 py-1 border-l border-gray-400`}>
                    <div className="text-base font-medium">
                      {vendor.name} <span className="text-sm font-normal">{vendor.contactNo}</span>
                    </div>
                    <div className="text-sm font-medium">{vendor?.shopName} </div>
                    <div className="text-sm  flex items-center gap-1 text-muted-foreground">
                      {vendor?.address?.societyName}, Plot No {vendor?.address?.plotNo}
                      {', Sector '}
                      {vendor?.address?.sector}
                    </div>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

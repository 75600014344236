import React from 'react';
import { Button } from '@/components/ui/button';
import { useCart } from '@/hooks/useCart';
import { Plus, Minus } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';

export function ProductCard({ id = 'none', product, onSelect, category, isCartBlocked = false }) {
  const { cart, addToCart, removeFromCart, updateQuantity } = useCart();
  const { user } = useUser();

  const cartItem = cart.find(
    (item) =>
      item.id === product.productId &&
      item.variant.inventoryId === product.productVariants[0].inventoryId
  );

  const quantity = cartItem ? cartItem.quantity : 0;

  const handleAddToCart = (e) => {
    e.stopPropagation();
    if (quantity === 0) {
      addToCart({
        id: product.productId,
        name: product.productName,
        image: product.productImageUrl,
        mrp: product.productVariants[0].mrp || product.mrp,
        price: product.productVariants[0].netPrice || product.netPrice,
        quantity: 1,
        variant: product.productVariants[0],
      });
    }
  };

  const handleIncrease = (e) => {
    e.stopPropagation();
    if (quantity < 9) {
      updateQuantity(product.productId, product.productVariants[0].inventoryId, 1);
    }
  };

  const handleDecrease = (e) => {
    e.stopPropagation();
    if (quantity > 1) {
      updateQuantity(product.productId, product.productVariants[0].inventoryId, -1);
    } else if (quantity === 1) {
      removeFromCart(product.productId, product.productVariants[0].inventoryId);
    }
  };

  const discount = (((product.mrp - product.netPrice) / product.mrp) * 100).toFixed(0);

  return (
    <div
      className={`bg-white rounded-lg overflow-hidden border relative ${
        product?.available ? 'cursor-pointer' : 'opacity-65 cursor-not-allowed'
      }`}
      onClick={() => {
        if (product?.available && product.productVariants.length > 1) {
          onSelect(product);
        }
      }}
    >
      {!product?.available && (
        <h2 className="absolute text-black font-bold z-50 top-1/2 text-center w-full -translate-y-1/2 text-balance text-base">
          Not Available
        </h2>
      )}
      <div className="relative">
        {Number(discount) > 0 && category !== 'Dairy' && (
          <div className="absolute top-2 left-2 bg-[#39c55e] text-white text-xs px-2 py-1 rounded">
            {discount}% OFF
          </div>
        )}
        <img
          loading="lazy"
          src={product.productImageUrl}
          alt={product.productName}
          className="w-full h-32 object-cover"
        />
        {user?.userType === 'user' && (
          <div className="absolute -bottom-3 right-2">
            {quantity === 0 ? (
              <Button
                variant="secondary"
                size="sm"
                className="border border-[#39c55e]"
                onClick={handleAddToCart}
                disabled={!product?.available || isCartBlocked}
              >
                Add
              </Button>
            ) : (
              <div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center bg-white border border-[#39c55e] rounded-md"
              >
                <Button variant="ghost" size="sm" className="p-0 h-8 w-8" onClick={handleDecrease}>
                  <Minus className="h-4 w-4" />
                </Button>
                <span className="px-2 font-medium">{quantity}</span>
                <Button
                  variant="ghost"
                  size="sm"
                  className="p-0 h-8 w-8"
                  onClick={handleIncrease}
                  disabled={quantity >= 9}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="p-3 space-y-2">
        <h3 className="font-medium text-sm">{product.productName}</h3>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <span className="text-sm font-bold">₹{product?.productVariants[0]?.netPrice}</span>
            {product.mrp !== product.netPrice && category !== 'Dairy' && (
              <span className="text-xs text-gray-500 line-through">
                ₹{product?.productVariants[0].mrp}
              </span>
            )}
          </div>
          <div className="text-sm border rounded-md px-2 py-1">
            {product?.productVariants[0].variant || 1} {product?.productVariants[0].unit}
          </div>
        </div>
      </div>
    </div>
  );
}

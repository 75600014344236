import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { History, Package, Phone, ShoppingBag, Store } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useVendor } from '@/contexts/VendorContext';
import axiosInstance from '@/utils/axiosInstance';
import { useUser } from '@/contexts/UserContext';

export default function BottomNav({ page = 'dashboard' }) {
  const { selectedVendor, setSelectedVendor } = useVendor();
  const [recentOrders, setRecentOrders] = useState(0);
  const { user } = useUser();

  const renderNavItem = (to, icon, label, isActive, badgeCount = 0) => {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Link
            to={to}
            className={`flex flex-col relative items-center justify-center p-2 transition-colors duration-200 ${
              isActive ? 'text-blue-700' : 'text-gray-500 hover:text-primary'
            }`}
          >
            {icon}
            <span className={`text-xs mt-1 ${isActive ? 'font-semibold' : ''}`}>{label}</span>
            {badgeCount > 0 && (
              <div className="absolute top-0 right-0 h-6 w-6 flex items-center justify-center bg-green-600 text-white text-xs rounded-full">
                {badgeCount}
              </div>
            )}
          </Link>
        </TooltipTrigger>
        <TooltipContent side="top">
          <p>{label}</p>
        </TooltipContent>
      </Tooltip>
    );
  };

  useEffect(() => {
    const selectedVendorData = JSON.parse(localStorage.getItem('selectedVendor'));
    if (selectedVendorData) {
      setSelectedVendor(selectedVendorData);
    }
  }, []);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const apiRes = await axiosInstance.get(
          `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/orders/vendor?status=Pending&pageNo=0`,
          {
            headers: {
              sessionToken: localStorage.getItem('sessionToken'),
              refreshToken: localStorage.getItem('refreshToken'),
              'X-Vendor-Id': user?.id,
            },
          }
        );

        setRecentOrders(apiRes?.data?.length);
      } catch (error) {}
    };

    if (localStorage.getItem('userRole') === 'vendor' && user) {
      fetchOrder();
    }
  }, [user]);

  return (
    <nav>
      {localStorage.getItem('userRole') === 'user' && (
        <TooltipProvider>
          <nav className="w-full fixed bottom-0 max-w-md mx-auto py-0 px-4 flex items-center justify-around bg-gray-50  border-t border-gray-200 shadow-lg z-20">
            {renderNavItem(
              '/history',
              <History className="h-5 w-5" />,
              'Orders',
              page === 'history'
            )}
            {renderNavItem('/', <Store className="h-5 w-5" />, 'Store', page === 'dashboard')}
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={() => {
                    window.open(`tel:${selectedVendor?.contactNo}`);
                  }}
                  className="flex flex-col items-center justify-center p-2 text-gray-500 hover:text-primary transition-colors duration-200"
                >
                  <Phone className="h-5 w-5" />
                  <span className="text-xs mt-1">Seller</span>
                </button>
              </TooltipTrigger>
              <TooltipContent side="top">
                <p>Support</p>
              </TooltipContent>
            </Tooltip>
          </nav>
        </TooltipProvider>
      )}
      {localStorage.getItem('userRole') === 'vendor' && (
        <TooltipProvider>
          <nav className="w-full fixed bottom-0 max-w-md mx-auto py-0 px-4 flex items-center justify-around bg-gray-50 border-t border-gray-200 shadow-lg z-20">
            {renderNavItem(
              '/vendor',
              <ShoppingBag className="h-5 w-5" />,
              'Orders',
              page === 'orders',
              recentOrders
            )}
            {renderNavItem(
              '/vendor/store',
              <Store className="h-5 w-5" />,
              'Store',
              page === 'dashboard'
            )}
            {renderNavItem(
              '/vendor/product/pricing',
              <Package className="h-5 w-5" />,
              'Products',
              page === 'pricing'
            )}
          </nav>
        </TooltipProvider>
      )}
    </nav>
  );
}

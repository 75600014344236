import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : { userId: null, items: [] };
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;

    if (userId !== cart.userId) {
      // If the user has changed, clear the cart
      setCart({ userId, items: [] });
    } else {
      // Otherwise, just update the cart in localStorage
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (item) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.items.findIndex(
        (cartItem) =>
          cartItem.id === item.id &&
          cartItem.variant.inventoryId === item.variant.inventoryId
      );
      if (existingItemIndex > -1) {
        const newItems = [...prevCart.items];
        newItems[existingItemIndex].quantity += item.quantity;
        return { ...prevCart, items: newItems };
      }
      return { ...prevCart, items: [...prevCart.items, item] };
    });
  };

  const removeFromCart = (productId, variantId) => {
    setCart((prevCart) => ({
      ...prevCart,
      items: prevCart.items.filter(
        (item) =>
          !(item.id === productId && item.variant.inventoryId === variantId)
      ),
    }));
  };

  const updateQuantity = (productId, variantId, change) => {
    setCart((prevCart) => ({
      ...prevCart,
      items: prevCart.items
        .map((item) =>
          item.id === productId && item.variant.inventoryId === variantId
            ? { ...item, quantity: Math.max(0, item.quantity + change) }
            : item
        )
        .filter((item) => item.quantity > 0),
    }));
  };

  const clearCart = () => {
    setCart((prevCart) => ({ ...prevCart, items: [] }));
  };

  const refreshCart = () => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  };

  const handleUserChange = (newUserId) => {
    setCart({ userId: newUserId, items: [] });
  };

  // Wrapper functions to maintain the same interface for other components
  const wrappedFunctions = {
    cart: cart.items,
    addToCart,
    removeFromCart,
    updateQuantity,
    clearCart,
    refreshCart,
    handleUserChange,
  };

  return (
    <CartContext.Provider value={wrappedFunctions}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

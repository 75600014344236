import { useState, useEffect, useRef } from 'react';
import {
  ChevronLeft,
  MapPin,
  Phone,
  Minus,
  Plus,
  UserCircle,
  PlusCircle,
  Clock,
  Package,
  Info,
  TriangleAlertIcon,
  Notebook,
  Bike,
  ShoppingBag,
  X,
} from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from '@/components/ui/button';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '@/hooks/useCart';
import { CartProvider } from '../../hooks/useCart';
import BottomNav from '@/components/layouts/Footer/BottomNav';
import axiosInstance from '../../utils/axiosInstance';
import { useUser } from '../../contexts/UserContext';

const formatTime = (time) => {
  const [hours, minutes] = time.split(':');
  const date = new Date(0, 0, 0, hours, minutes);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const getExpressDeliverySlot = () => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const endTime = new Date(now.getTime() + 60 * 60 * 1000);
  const endHour = endTime.getHours();
  const endMinute = endTime.getMinutes();
  return `${currentHour.toString().padStart(2, '0')}:${currentMinute
    .toString()
    .padStart(
      2,
      '0'
    )}-${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
};

const Shopping = () => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [selectedDay, setSelectedDay] = useState(() => {
    if (new Date().getHours() > 18) {
      return 'tomorrow';
    } else {
      return 'today';
    }
  });
  const { cart, updateQuantity, refreshCart, clearCart } = useCart();
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { user, refreshUser } = useUser();
  const [notes, setNotes] = useState('');
  const [expressDelivery, setExpressDelivery] = useState(false);
  const [selfPickup, setSelfPickup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddressUpdateModalOpen, setIsAddressUpdateModalOpen] = useState(false);
  const navigate = useNavigate();

  const timeSlots = [
    // "08:00-10:00",
    '10:00-12:00',
    '12:00-14:00',
    '14:00-16:00',
    '16:00-18:00',
    '18:00-20:00',
  ];

  const handleUpdateQuantity = (id, variantId, change) => {
    updateQuantity(id, variantId, change);
  };

  const calculateTotal = () => {
    const selectedVendor = JSON.parse(localStorage.getItem('selectedVendor'));
    const subtotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const mrpTotal = cart.reduce((sum, item) => sum + item.mrp * item.quantity, 0);

    const discount = mrpTotal - subtotal;
    const platformFees = 5;
    const deliveryCharges = selectedVendor?.deliveryChargeWaived
      ? selectedVendor?.deliveryCharge || 0
      : 0;
    const totalAmount = subtotal + platformFees + deliveryCharges;
    const minimumOrderValue = selectedVendor?.minimumOrder || 0;
    const minimumOrderValueMet = subtotal < minimumOrderValue;

    return {
      subtotal,
      mrpTotal,
      discount,
      platformFees,
      deliveryCharges,
      total: totalAmount,
      minimumOrderValue,
      minimumOrderValueMet,
    };
  };

  const totals = calculateTotal();

  const handleSubmit = async () => {
    if (totals?.minimumOrderValueMet) {
      toast.info(
        'Minimum order amount must be greater than ' +
          totals.minimumOrderValue +
          '. Please add items worth ' +
          (totals.minimumOrderValue - totals.total) +
          ' Rs more to place order'
      );
      return;
    }

    if (
      !selectedAddress ||
      !selectedAddress.societyName ||
      !selectedAddress.locality ||
      !selectedAddress.city ||
      !selectedAddress.state ||
      !selectedAddress.country ||
      !selectedAddress.pinCode ||
      !selectedAddress?.contactNo
    ) {
      setIsSubmitting(false);
      toast.error('Address is Required');
      return;
    }

    const orderData = {
      mrp: totals.mrpTotal,
      discount: totals.discount,
      platformFees: totals.platformFees,
      deliveryCharges: totals.deliveryCharges,
      total: totals.total,
      contactNo: selectedAddress?.contactNo,
      deliveryAddress: `${selectedAddress?.unitNo}, ${selectedAddress?.societyName}, Plot/Pocket No ${selectedAddress?.plotNo}, Sector ${selectedAddress?.sector}, ${selectedAddress?.locality}, ${selectedAddress?.state}, ${selectedAddress?.district}, ${selectedAddress?.pinCode}, ${selectedAddress?.country}`,
      status: 'Pending',
      orderDate: new Date(),
      deliveryDate:
        selectedDay === 'today'
          ? new Date().toISOString().split('T')[0]
          : new Date(Date.now() + 86400000).toISOString().split('T')[0],
      timeSlot: expressDelivery ? getExpressDeliverySlot() : selectedTimeSlot,
      expressDelivery,
      selfPickup,
      note: notes,
      items: cart.map((item) => ({
        productId: item.id,
        inventoryId: item.variant.inventoryId,
        productName: item.name,
        quantity: item.quantity,
        productImageUrl: item.image,
        unit: item.variant.unit,
        mrp: item.mrp,
        netPrice: item.price,
      })),
    };

    if (orderData?.items?.length < 1) {
      toast.error('Cart is Empty! Try adding items to the cart');
      setIsSubmitting(false);
      return;
    }

    const addressId = selectedAddress?.id;

    try {
      const selectedVendorData = JSON.parse(localStorage.getItem('selectedVendor'));

      if (!selectedVendorData) {
        toast.info('No vendor is selected! Please select a vendor');
        vendor;
      }
      setIsSubmitting(true);
      await axiosInstance.post(
        `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/order?addressId=${addressId}`,
        orderData,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Vendor-Id': selectedVendorData.vendorId,
            'X-User-Id': user?.id,
            sessionToken: localStorage.getItem('sessionToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          },
        }
      );
      toast.success('Order placed successfully!');
      setIsSubmitting(false);
      clearCart();
      navigate('/history');
    } catch (error) {
      console.error('Error submitting order:', error);
      toast.error(error?.message || 'Failed to place order! Please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSlotAvailable = (slot) => {
    const currentTime = new Date();
    const [start] = slot.split('-');
    const [startHours, startMinutes] = start.split(':');
    const slotStartTime = new Date();
    slotStartTime.setHours(Number.parseInt(startHours), Number.parseInt(startMinutes), 0, 0);

    if (selectedDay === 'tomorrow') {
      return true;
    } else {
      return slotStartTime > currentTime;
    }
  };

  const getAvailableTimeSlots = (day) => {
    return timeSlots.filter((slot) => {
      if (day === 'tomorrow') {
        return true;
      } else {
        return isSlotAvailable(slot);
      }
    });
  };

  useEffect(() => {
    const availableSlots = getAvailableTimeSlots(selectedDay);

    if (availableSlots.length > 0) {
      setSelectedTimeSlot(
        selectedTimeSlot && availableSlots.includes(selectedTimeSlot)
          ? selectedTimeSlot
          : availableSlots[0]
      );
    } else if (selectedDay === 'today') {
      setSelectedDay('tomorrow');
      const tomorrowSlots = getAvailableTimeSlots('tomorrow');
      setSelectedTimeSlot(tomorrowSlots[0]);
    }
  }, [selectedDay]);

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshCart();
  }, []);

  const fetchAddress = async () => {
    const address = user?.addressModel;
    setAddresses(address);
    const defaultAddress = address?.find((item) => item.defaultAdd);
    if (!defaultAddress) {
      return;
    }
    setSelectedAddress(defaultAddress || address[0]);
  };

  const handleAddressSelection = (address) => {
    setSelectedAddress(address);
    setIsOpen(false);
  };

  useEffect(() => {
    fetchAddress();
  }, [user]);

  useEffect(() => {
    if (cart.length === 0) {
      navigate('/');
    }
  }, [cart]);

  return (
    <div className="bg-gray-100 min-h-screen relative">
      <div className="max-w-md mx-auto bg-white shadow-md">
        {/* Header */}
        <div className="flex items-center p-4 border-b sticky top-0 bg-white z-10">
          <Link to="/">
            <ChevronLeft className="w-6 h-6 mr-2 text-gray-600" />
          </Link>
          <h1 className="text-lg font-semibold">Checkout</h1>
          <UserMenu />
        </div>

        {/* Order Items */}
        <div className="p-4 border-b">
          <div className="flex justify-between items-center mb-2">
            <h2 className="font-semibold flex items-center gap-2">
              <Package className="w-5 h-5" /> Order Items <span>{cart.length}</span>
            </h2>
            <Button variant="outline" className="h-fit px-2 py-1" onClick={() => navigate('/')}>
              <PlusCircle className="w-4 h-4 mr-1" />
              Add Item
            </Button>
          </div>
          {cart.length === 0 && (
            <p className="text-center text-sm text-red w-2/3 text-red-500 mx-auto text-balance ">
              Your Cart is Empty! Try Adding Some Items
            </p>
          )}
          {cart.map((item) => (
            <div
              key={`${item.id}-${item.variant.inventoryId}`}
              className="flex items-center justify-between mb-2"
            >
              <div className="flex gap-2  w-full items-center">
                <img
                  loading="lazy"
                  src={item.image || `/images/${item.name.toLowerCase()}.png`}
                  alt={item.name}
                  className="w-16 max-h-32 mr-2 rounded object-cover"
                />
                <div className="w-full">
                  <div className="flex items-center justify-between gap-2 w-full ">
                    <p className="font-semibold">{item.name}</p>
                    <div className="flex items-center bg-[#39c55e] text-white rounded-md px-1">
                      <button
                        onClick={() => handleUpdateQuantity(item.id, item.variant.inventoryId, -1)}
                        className="p-1  rounded-full"
                      >
                        <Minus size={16} />
                      </button>
                      <span className="px-2">{item.quantity}</span>
                      <button
                        onClick={() => {
                          if (item.quantity !== 9) {
                            handleUpdateQuantity(item.id, item.variant.inventoryId, 1);
                          }
                        }}
                        className="p-1  rounded-full"
                      >
                        <Plus size={16} />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 justify-between w-full ">
                    <p className="text-sm text-gray-500">
                      {item.variant.variant} {item.variant.unit}
                    </p>
                    <span className="ml-2 font-semibold">₹ {item.price * item.quantity}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Order Summary */}
        <div className="p-4 border-b">
          <h2 className="font-semibold mb-2">Order Summary</h2>
          <div className="flex justify-between mb-1">
            <span className=" flex items-center gap-2 ">
              <span className="text-gray-600 flex items-center gap-2">
                <Notebook className="h-4 w-4 inline-block" /> Items total
              </span>
              <span className="text-xs px-[6px] py-[.5px] bg-blue-50 rounded-full text-blue-600 font-medium">
                Saved ₹{totals.discount}
              </span>
            </span>
            <span className="flex items-center gap-2">
              <div className="line-through text-gray-400">₹{totals?.mrpTotal}</div> ₹
              {totals.subtotal.toFixed(2)}
            </span>
          </div>
          <div className="flex justify-between mb-1">
            <span className=" text-gray-600 flex items-center gap-2">
              <ShoppingBag className="h-4 w-4 inline-block" />
              Platform Fees
            </span>
            <span>₹ {totals.platformFees.toFixed(2)}</span>
          </div>
          <div className="flex justify-between mb-1">
            <span className="flex justify-center items-center gap-1">
              <span className=" text-gray-600 flex items-center gap-2">
                <Bike className="h-4 w-4 inline-block" /> Delivery charge
              </span>
              <div className="relative group">
                <Info className="h-4 text-red-600 cursor-pointer mt-1 w-4" />
                <div className="absolute bg-yellow-200 px-4 hidden group-hover:block py-1 w-64 text-xs text-yellow-900 -left-3 bottom-full rounded-md">
                  Delivery charges may be subject to change based on seller and location.
                </div>
              </div>
            </span>
            <span>₹ {totals.deliveryCharges.toFixed(2)}</span>
          </div>
          <div className="flex justify-between font-semibold mt-2 border-t border-slate-300 pt-1">
            <span>TOTAL</span>
            <span>₹ {totals.total.toFixed(2)}</span>
          </div>
          {totals?.minimumOrderValueMet && (
            <div className="w-full px-4 py-2 bg-orange-200 my-2 rounded-md">
              <TriangleAlertIcon className="h-5 w-5 inline-block mr-3" />
              <span className="text-sm">
                The minimum order value is <strong>₹{totals.minimumOrderValue}</strong>. Your
                current order total is{' '}
                <strong>
                  ₹{totals.subtotal}{' '}
                  <span className="text-red-500 font-semibold">
                    {' '}
                    Please add ₹{totals.minimumOrderValue - totals.subtotal} more to meet the
                    minimum order value.
                  </span>
                </strong>
                .
              </span>
            </div>
          )}
        </div>

        {/* Delivery Details */}
        <div className="p-4 border-b">
          <div className="flex justify-between items-center mb-2">
            <h2 className="font-semibold">Delivery Address</h2>
          </div>
          {selectedAddress && (
            <div className="bg-gray-100 p-3 rounded-lg">
              <div className="flex items-start mb-2">
                <MapPin className="w-5 h-5 mr-2 mt-1 text-gray-600" />
                <div className="w-full">
                  <p className="font-medium">{selectedAddress.societyName}</p>
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-gray-600">
                      {selectedAddress.unitNo ? `${selectedAddress.unitNo}, ` : ''}
                      plot/pocket No {selectedAddress.plotNo}, Sector {selectedAddress.sector}
                    </p>
                  </div>
                  <p className="text-sm text-gray-600">{`${selectedAddress.locality}, ${selectedAddress.district}, ${selectedAddress.state}, ${selectedAddress.pinCode}`}</p>
                </div>
              </div>
              <div className="flex items-center text-sm text-gray-600">
                <Phone className="w-4 h-4 mr-2" />
                <p>{selectedAddress?.contactNo || 'Not Provided'}</p>
              </div>
            </div>
          )}
        </div>

        {/* Time Slot */}
        <div className="p-4 border-b select-none">
          <h2 className="font-semibold mb-2">Delivery Time</h2>
          <div className="flex items-center justify-between mb-4 select-none">
            <div title="Timing 10 AM to 8 PM" className="flex items-center">
              <input
                type="checkbox"
                id="expressDelivery"
                checked={expressDelivery}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelfPickup(false);
                  }
                  setExpressDelivery(e.target.checked);
                }}
                className="mr-2"
                disabled={new Date().getHours() >= 20 || new Date().getHours() < 10}
              />
              <label htmlFor="expressDelivery" className="text-sm cursor-pointer">
                Express (next hour)
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="selfPickup"
                checked={selfPickup}
                onChange={(e) => {
                  if (e.target.checked) {
                    setExpressDelivery(false);
                  }
                  setSelfPickup(e.target.checked);
                }}
                className="mr-2"
                // disabled={expressDelivery}
              />
              <label htmlFor="selfPickup" className="text-sm cursor-pointer">
                Self Pickup
              </label>
            </div>
          </div>

          <div className="flex mb-4">
            <button
              className={`flex-1 py-2 rounded-l transition-colors ${
                expressDelivery
                  ? 'cursor-not-allowed bg-gray-300 opacity-55'
                  : selectedDay === 'today'
                    ? 'bg-[#39c55e] text-white'
                    : 'bg-gray-200 text-gray-700'
              }`}
              onClick={() => {
                setSelectedDay('today');
              }}
              disabled={expressDelivery}
            >
              Today
            </button>
            <button
              className={`flex-1 py-2 rounded-r transition-colors ${
                expressDelivery
                  ? 'cursor-not-allowed bg-gray-300 opacity-55'
                  : selectedDay === 'tomorrow'
                    ? 'bg-[#39c55e] text-white'
                    : 'bg-gray-200 text-gray-700'
              }`}
              onClick={() => setSelectedDay('tomorrow')}
              disabled={expressDelivery}
            >
              Tomorrow
            </button>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {timeSlots.map((slot) => {
              const [start, end] = slot.split('-');
              const displaySlot = `${formatTime(start)} - ${formatTime(end)}`;
              return (
                <button
                  key={slot}
                  className={`py-2 px-2 text-sm rounded transition-colors ${
                    expressDelivery
                      ? 'cursor-not-allowed bg-gray-300 opacity-55'
                      : selectedTimeSlot === slot
                        ? 'bg-blue-500 text-white'
                        : isSlotAvailable(slot)
                          ? 'bg-gray-100  text-gray-700 hover:bg-gray-300'
                          : 'bg-gray-300  opacity-70 text-gray-500 cursor-not-allowed'
                  }`}
                  onClick={() => isSlotAvailable(slot) && setSelectedTimeSlot(slot)}
                  disabled={!isSlotAvailable(slot) || expressDelivery}
                >
                  <Clock className="w-4 h-4 inline-block mr-2" />
                  {displaySlot}
                </button>
              );
            })}
          </div>
        </div>

        {/* Notes */}
        <div className="p-4">
          <h2 className="font-semibold mb-2">Notes</h2>
          <textarea
            className="w-full p-2 border outline-none focus:border-black rounded"
            placeholder="Add your notes here"
            rows="2"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          ></textarea>
        </div>

        {/* Confirm Button */}
        <div className="px-4 pt-2 py-4 border-t mb-12 bg-white">
          <button
            onClick={() => {
              !selectedAddress?.unitNo ? setIsAddressUpdateModalOpen(true) : handleSubmit();
            }}
            disabled={isSubmitting || totals?.minimumOrderValueMet}
            className={`w-full py-2 bg-[#39c55e] cursor-pointer  rounded font-semibold hover:bg-[#2ea34d] transition-colors ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            } ${totals?.minimumOrderValueMet ? 'bg-slate-200 hover:bg-slate-200 text-black/40' : 'text-white'}`}
          >
            {isSubmitting ? 'Placing Order...' : 'Place Order'}
          </button>
          {totals.subtotal < totals.minimumOrderValue && (
            <p className="text-red-500 text-sm mt-2 text-center">
              Please meet the minimum order value to place your order.
            </p>
          )}
        </div>
      </div>
      <UpdateAddressModal
        isOpen={isAddressUpdateModalOpen}
        setIsOpen={setIsAddressUpdateModalOpen}
        fetchAddress={fetchAddress}
        handleSubmit={handleSubmit}
      />
      <BottomNav />

      {/* Address Selection Popup */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black max-w-sm mx-auto bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setIsOpen(false)}
        >
          <div className="bg-white rounded-lg p-6 w-11/12" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-xl font-semibold mb-4">Select Address</h2>
            <div className="max-h-60 overflow-y-auto">
              {addresses.map((address) => (
                <div
                  key={address.id}
                  className="flex items-start space-x-2 mb-4 p-2 rounded hover:bg-gray-100 transition-colors"
                >
                  <input
                    type="radio"
                    id={`address-${address.id}`}
                    name="address"
                    checked={selectedAddress?.id === address.id}
                    onChange={() => handleAddressSelection(address)}
                    className="mt-1"
                  />
                  <label
                    htmlFor={`address-${address.id}`}
                    className="text-sm cursor-pointer flex-1"
                  >
                    <p className="font-medium">{address.societyName}</p>
                    <p className="text-gray-600">{`${address.unitNo}, plot/pocket No ${address.plotNo}, Sector ${address.sector}`}</p>
                    <p className="text-gray-600">{`${address.locality}, ${address.district}, ${address.state}, ${address.pinCode}`}</p>
                  </label>
                </div>
              ))}
            </div>
            <div className="flex w-full justify-end space-x-2">
              <Button variant="outline" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                className="text-white bg-[#39c55e] hover:bg-[#39c55e]/90"
                onClick={() => setIsOpen(false)}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UpdateAddressModal = ({ isOpen = false, setIsOpen, fetchAddress, handleSubmit }) => {
  const { user } = useUser();
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const addressRef = useRef(null);
  const { refreshUser } = useUser();

  const updateAddress = async () => {
    if (!user?.addressModel.at(0)) {
      toast.error('Please add your address in profile to proceed');
      return;
    }

    if (!address?.unitNo) {
      toast.error('Please enter a valid flat/house number');
      return;
    }

    const apiData = {
      ...user?.addressModel.at(0),
      ...address,
    };

    setLoading(true);

    try {
      await axios.post(`${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/address`, apiData, {
        headers: {
          'X-User-Id': user?.id,
          sessionToken: localStorage.getItem('sessionToken'),
          refreshToken: localStorage.getItem('refreshToken'),
        },
      });

      refreshUser(user?.contactNo);

      fetchAddress();
      setIsOpen(false);
      handleSubmit();
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Something went wrong! Please try again!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addressRef.current && !addressRef.current.contains(event?.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <dialog open={isOpen} className="fixed top-0 bg-transparent h-full w-full z-50 max-w-md">
      <div className="h-full w-full bg-black/50 flex items-center justify-center">
        <div
          ref={addressRef}
          className="max-w-md w-2/3 overflow-hidden py-3 px-4 h-fit bg-white rounded-md relative flex flex-col gap-2 items-center justify-center"
        >
          <X
            onClick={() => setIsOpen(false)}
            className="h-6 w-6 text-red-600 cursor-pointer absolute top-3 right-3"
          />
          <h2 className="text-green-800 font-bold">Update Address</h2>

          <div className="w-full">
            <label htmlFor="flatNo" className="block text-sm font-medium">
              Flat/House No
              <input
                id="flatNo"
                type="text"
                value={address?.unitNo || ''}
                onChange={(e) => setAddress((prev) => ({ ...prev, unitNo: e.target.value }))}
                className="py-1 pl-3 focus:outline-none border border-black rounded-sm w-full mt-1"
                placeholder="Enter flat/house number"
              />
            </label>
          </div>
          <button
            onClick={updateAddress}
            disabled={loading}
            className="px-5 py-1 bg-green-600 text-white rounded-md flex items-center gap-1"
          >
            {loading ? 'Placing...' : 'Place Order'}
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default function ShoppingCart() {
  return (
    <CartProvider>
      <Shopping />
    </CartProvider>
  );
}

const UserMenu = () => {
  return (
    <Link to="/profile" className="ml-auto mr-2">
      <UserCircle className="h-7 w-7" />
    </Link>
  );
};

import React, { useMemo } from 'react';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { useCart } from '@/hooks/useCart';
import { Button } from '@/components/ui/button';
import { Minus, Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

export function ProductDrawer({ product, isOpen, onClose, isCartBlocked = false }) {
  const { cart, addToCart, updateQuantity } = useCart();
  const navigate = useNavigate();
  const { user } = useUser();

  const cartItems = useMemo(() => {
    if (!product) return [];
    return cart.filter((item) => item.id === product.productId);
  }, [cart, product]);

  const calculateCartTotal = useMemo(() => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  }, [cartItems]);

  const handleQuantityChange = (variant, change) => {
    if (change > 0) {
      addToCart({
        id: product.productId,
        name: product.productName,
        image: product.productImageUrl,
        mrp: variant.mrp || product.mrp,
        price: variant.netPrice || product.netPrice,
        quantity: 1,
        variant: variant,
      });
    } else {
      updateQuantity(product.productId, variant.inventoryId, change);
    }
  };

  const getItemQuantity = (inventoryId) => {
    const item = cartItems.find((item) => item.variant.inventoryId === inventoryId);
    return item ? item.quantity : 0;
  };

  const handleGoToCart = () => {
    onClose();
    navigate('/cart');
  };

  if (!product) return null;

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent side="bottom" className="max-w-md mx-auto py-0 px-0 rounded-t-3xl">
        <div className="flex flex-col h-full">
          <div className="relative w-full h-40 rounded-t-2xl overflow-hidden">
            <img
              loading="lazy"
              src={product.productImageUrl}
              alt={product.productName}
              className="w-full h-full object-cover"
            />
            {product.mrp > product.netPrice && (
              <div className="absolute top-2 left-2 text-white bg-[#39c55e] text-xs px-2 py-1 rounded">
                {(((product.mrp - product.netPrice) / product.mrp) * 100).toFixed(0)}% OFF
              </div>
            )}
          </div>
          <div className="flex-grow overflow-y-auto px-4 py-2">
            <SheetHeader className="text-left mb-2">
              <SheetTitle className="text-xl font-bold">{product.productName}</SheetTitle>
            </SheetHeader>
            <div className="grid gap-2">
              {product.productVariants.map((variant) => (
                <div
                  key={variant.inventoryId}
                  className="flex items-center justify-between border rounded-lg p-2"
                >
                  <div className="flex flex-col items-start justify-start">
                    <span className="text-sm font-medium">
                      {variant.variant} {variant.unit}
                    </span>
                    <p className="text-xs text-gray-400">Net Price: ₹{variant.netPrice}</p>
                  </div>
                  {!isCartBlocked && (
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleQuantityChange(variant, -1)}
                        disabled={getItemQuantity(variant.inventoryId) <= 0}
                        className="h-7 w-7 p-0"
                      >
                        <Minus className="h-4 w-4" />
                      </Button>
                      <span className="w-8 text-center">
                        {getItemQuantity(variant.inventoryId)}
                      </span>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleQuantityChange(variant, 1)}
                        disabled={getItemQuantity(variant.inventoryId) >= 9}
                        className="h-7 w-7 p-0"
                      >
                        <Plus className="h-4 w-4" />
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="border-t bg-white px-4 py-3 mt-auto">
            <div className="flex items-center justify-between mb-3">
              <span className="font-medium">Total:</span>
              <span className="font-bold text-lg">₹{calculateCartTotal.toFixed(2)}</span>
            </div>
            <div className="flex items-center gap-2">
              <Button
                className="w-full bg-gray-900 hover:bg-gray-800 text-white py-4 cursor-pointer"
                onClick={() => onClose()}
              >
                Continue
              </Button>
              {user?.userType === 'user' && (
                <Button
                  className="w-full bg-[#39c55e] hover:bg-[#2ea34d] text-white py-4 cursor-pointer"
                  onClick={handleGoToCart}
                  disabled={calculateCartTotal === 0 || isCartBlocked}
                >
                  {!isCartBlocked ? 'Go To Cart' : 'Not Available'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}

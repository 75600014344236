import axiosInstance from './axiosInstance';

export default async function fetchUser(phone) {
  const mobile = phone?.startsWith('+91') ? phone?.replace('+91', '') : phone;
  if (!mobile) {
    return;
  }
  return await fetchProfile(mobile);
}

const fetchProfile = async (phone) => {
  try {
    const sessionToken = localStorage.getItem('sessionToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const { data } = await axiosInstance.get(
      `${import.meta.env.VITE_API_URL}/rest/subziwale/api/v1/user?mobileNo=%2B91${phone}`,
      {
        headers: {
          sessionToken,
          refreshToken,
        },
      }
    );
    if (data.user) {
      return data?.user;
    } else if (data.vendor) {
      return data?.vendor;
    }
    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
